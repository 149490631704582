@use 'shared/dist/styles/variables.scss' as v;

$slider-padding: 5px;

.toggle {
  background-color: v.$color-midnight;
  border-radius: v.$border-radius-large;
  padding: $slider-padding;
  position: relative;
  display: inline-block;
  width: 125px;
  height: 40px;

  .slider {
    display: flex;
    height: 100%;
    transition: .4s;
    position: relative;

    &::before {
      background-color: v.$color-dusk;
      border-radius: 15px;
      bottom: 0;
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      transition: .4s;
      width: calc(50%);
      z-index: 0;
    }

    .option-text {
      align-items: center;
      color: v.$color-white;
      display: inline-flex;
      height: 100%;
      justify-content: center;
      position: relative;
      width: 50%;
      z-index: 1;
    }
  }

  input { 
    appearance: none;
    height: 100%;
    margin: 0;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;

    &:checked {
      + .slider::before {
        transform: translateX(-100%);
      }
    }
  }
}
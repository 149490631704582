@import "shared/dist/styles/variables";

// We are not using these anymore. We should use our clickable/button or clickable/link
button.MuiButton {
  &-root {
    text-transform: none;
    padding: 0.2rem 2rem;
    border-radius: 25px;
    font-size: $font-size-sm;
    white-space: nowrap;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }

  // Filled Button Information
  &-contained {
    background-color: $color-seafoam;
    border: 1px solid transparent;
    color: $color-lake;

    &:hover {
      background-color: transparent;
      border: 1px solid $color-dusk;
      color: $color-dusk;
    }
    &Primary.Mui-disabled {
      background-color: $color-seafoam;
      color: $color-midnight;
      opacity: 0.3;
    }
  }

  // Open Button Action
  &-outlined {
    background-color: transparent;
    border: 1px solid $color-seafoam;
    color: $color-midnight;

    &:hover {
      background-color: $color-seafoam;
      color: $color-midnight;
    }
  }
}

@use "./breakpoints.scss" as b;

$graphik-family: "Graphik", "Helvetica", "Arial", sans-serif;
$miller-family: "Miller", "Georgia", "Times New Roman", serif;

$font-size-4xl: 68px;
$font-size-3xl: 48px;
$font-size-2xl: 38px;
$font-size-xl: 28px;
$font-size-lg: 21px;
$font-size-md: 18px;
$font-size-sm: 15px;
$font-size-xs: 13px;
$font-size-2xs: 12px;
$font-size-3xs: 11px;

$font-line-height-sm: 1.2;
$font-line-height-md: 1.4;
$font-line-height-lg: 1.67;
$font-line-height-xl: 1.8;
$font-line-height-2xl: 2;

.font {
  &-graphik {
    font-family: $graphik-family !important;
  }

  &-miller {
    font-family: $miller-family !important;
  }

  &-fund {
    font-family: $graphik-family;
    font-weight: bold;
  }
}

h1,
h2,
h3 {
  font-family: $miller-family;
  font-weight: 400;
  margin-bottom: 18px;
}

h4,
h5,
h6 {
  margin-bottom: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: 0;
  margin-top: 0;
}

h4 {
  font-weight: 600;
  font-family: $graphik-family;
  @include b.breakpoint(b.$screen-md) {
    font-family: $miller-family;
    font-weight: 400;
  }
}

h5,
h6 {
  font-family: $graphik-family;
  font-weight: 600;
}

h1 {
  font-size: $font-size-3xl;
  line-height: 1.2;
  @include b.breakpoint(b.$screen-md) {
    font-size: $font-size-4xl;
  }
}

h2 {
  font-size: $font-size-2xl;
  line-height: 1.26;
  @include b.breakpoint(b.$screen-md) {
    font-size: $font-size-3xl;
    line-height: 1.2;
  }
}

h3 {
  font-size: $font-size-xl;
  line-height: 1.28;
  @include b.breakpoint(b.$screen-md) {
    font-size: $font-size-2xl;
    line-height: 1.26;
  }
}

h4 {
  font-size: $font-size-lg;
  line-height: 1.47;
  @include b.breakpoint(b.$screen-md) {
    font-size: $font-size-xl;
    line-height: 1.353;
  }
}

h5 {
  font-size: $font-size-md;
  line-height: 1.44;
  @include b.breakpoint(b.$screen-md) {
    font-size: $font-size-lg;
    line-height: 1.47;
  }
}

h6 {
  font-size: $font-size-sm;
  line-height: 1.6;
  @include b.breakpoint(b.$screen-md) {
    font-size: $font-size-md;
    line-height: 1.44;
  }
}

.h1 {
  @extend h1;
}

.h2 {
  @extend h2;
}

.h3 {
  @extend h3;
}

.h4 {
  @extend h4;
}

.h5 {
  @extend h5;
}

.h6 {
  @extend h6;
}

.text {
  &-small {
    font-size: $font-size-2xs;
    line-height: 1.4;
    @include b.breakpoint(b.$screen-md) {
      font-size: $font-size-xs;
      line-height: 1.46;
    }
  }

  &-regular {
    font-size: $font-size-xs;
    line-height: 1.46;
    @include b.breakpoint(b.$screen-md) {
      font-size: $font-size-sm;
      line-height: 1.467;
    }
  }

  &-large {
    font-size: $font-size-sm;
    line-height: 1.6;
    @include b.breakpoint(b.$screen-md) {
      font-size: $font-size-md;
      line-height: 1.67;
    }
  }

  &-bold {
    font-weight: 600 !important;
  }

  &-thin {
    font-weight: 300 !important;
  }

  &-capitilize {
    text-transform: capitalize !important;
  }

  &-underline {
    text-decoration: underline !important;
  }

  &-decoration-none {
    text-decoration: none !important;
  }
}

// All p elements with no text-<size> defined default to this:
p {
  @extend .text-regular;
}
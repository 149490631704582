.change-username-container {
  padding: 0 !important;

  .MuiTypography {
    &-caption {
      margin-bottom: 0.5rem !important;
    }
    &-h5 {
      margin-bottom: 1.25rem;
    }
  }
  .action-buttons {
    margin-top: 1.5rem;
  }
}

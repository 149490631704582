@use "shared/dist/styles/variables.scss" as v;

$end-size: v.$font-size-sm;
$value-size: v.$font-size-md;

@mixin circle-size($size) {
  height: $size;
  line-height: $size;
  width: $size;
}

@mixin size-small {
  .strategy-risk-indicator__minimum,
  .strategy-risk-indicator__maximum,
  .strategy-risk-indicator__value {
    font-size: v.$font-size-2xs;
  }

  .strategy-risk-indicator__minimum,
  .strategy-risk-indicator__maximum {
    @include circle-size(15px);
  }

  .strategy-risk-indicator__value {
    @include circle-size(18px);
  }
}

@mixin size-medium {
  .strategy-risk-indicator__minimum,
  .strategy-risk-indicator__maximum,
  .strategy-risk-indicator__value {
    font-size: v.$font-size-xs;
  }

  .strategy-risk-indicator__minimum,
  .strategy-risk-indicator__maximum {
    @include circle-size(18px);
  }

  .strategy-risk-indicator__value {
    @include circle-size(21px);
  }
}

@mixin size-large {
  .strategy-risk-indicator__minimum,
  .strategy-risk-indicator__maximum,
  .strategy-risk-indicator__value {
    font-size: v.$font-size-sm;
  }

  .strategy-risk-indicator__minimum,
  .strategy-risk-indicator__maximum {
    @include circle-size(21px);
  }

  .strategy-risk-indicator__value {
    @include circle-size(25px);
  }
}

.strategy-risk-indicator {
  align-items: center;
  color: v.$c-text-regular;
  display: flex;
  flex-direction: row;
  font-size: v.$font-size-xs;
  height: 1rem;
  position: relative;

  &__bar {
    background-color: v.$c-border;
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 0;
  }

  &__minimum,
  &__maximum,
  &__value-container {
    z-index: 1;
  }

  &__minimum,
  &__maximum {
    text-align: center;
    background-color: v.$palette-gray-20;
    border-radius: 50%;
    color: v.$color-black;
    flex: 0 0 auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &--highlighted {
      background-color: v.$c-text-dark;
      color: v.$color-white;
    }
  }

  &__minimum {
    left: 0;
  }

  &__maximum {
    right: 0;
  }

  &__value-container {
    flex: 0 0 auto;
  }

  &__value {
    text-align: center;
    background-color: v.$c-text-dark;
    border-radius: 50%;
    color: v.$color-white;
    font-size: v.$font-size-2xs;
    height: $value-size;
    transform: translateX(-50%);
    width: $value-size;
  }

  &--small {
    @include size-small;
  }

  &--regular {
    @include size-small;

    @include v.breakpoint(v.$screen-md) {
      @include size-medium;
    }
  }

  &--large {
    @include size-medium;

    @include v.breakpoint(v.$screen-md) {
      @include size-large;
    }
  }
}

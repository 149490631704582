@use 'shared/dist/styles/variables' as *;
@use 'shared/dist/styles/variables/typography.scss';
@import '/src/styles/mixins.scss';

.profile-quick-actions-component {
  flex: 0 0 auto;
  font-weight: 300;
  align-items: center;

  .MuiButton-label {
    margin-left: $spacing-md;
  }
  
  .profile-button {
    transition: all 200ms ease-in-out;

    &.loading {
      .profile-photo {
        background-color: $color-light-grey;
        animation: blinker 1s linear infinite;
      }
    }

    &:hover {
      opacity: 0.8;
    }

    .icon-arrow-dropdown {
      @media (min-width: $screen-lg) {
        display: block;
      }

      color: $color-smoke;
      display: none;

    }
  }

  .profile {
    &-button {
      min-width: 0;
      padding: 0;
      text-transform: inherit;
      font-weight: 400;

      &:hover {
        background: none;
      }
    }

    &-name,
    &-photo {
      color: $color-midnight;
    }

    &-greeting {
      text-align: left;
      display: none;
      margin: 0 1rem;

      @media (min-width: 769px) {
        display: inline-block;
      }
    }

    $size: 40px;

    &-photo {
      @extend .h4;
      @extend .font-graphik;

      align-items: center;
      background: $color-seafoam;
      border-radius: $size;
      color: $color-midnight;
      display: flex;
      flex-direction: column;
      height: $size;
      justify-content: center;
      margin-right: 5px;
      width: $size;
    }

  }
}

.profile-menu-list {
  margin-left: 0;
  margin-top: 3rem;

  @media (min-width: 600px) {
    margin-left: -0.5rem;
  }

  .MuiPaper-rounded {
    border-radius: 10px;
  }

  .MuiMenu-list {
    padding: 1rem 1.5rem;
    width: 100%;
  }

  .MuiMenu-paper {
    @extend .smallShadow;
  }

  .MuiListItem-root {
    display: block;
    min-height: 0;
    padding: 0.5rem 0;
  }

  .MuiSvgIcon-root {
    height: 1rem;
  }

  .MuiMenuItem-root {
    cursor: default;
    width: 100%;

    &:hover {
      background-color: transparent;
    }
  }

  .label,
  .value {
    display: block;
    color: $color-midnight;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .label {
    @extend .text-small;
    color: $color-smoke;
  }

  .link {
    @extend .text-regular;
    display: flex;
    align-items: center;
    color: $color-dusk;
    text-decoration: none;
    transition: color 200ms ease-in-out;

    &.logout {
      cursor: pointer;
    }

    &:hover {
      color: $color-light-blue;
    }
  }
}

.notifications-modal {

  .MuiDialogContent-root {
    padding: 0;
  }
  
  .MuiPaper-root {
    padding-top: 0;
    border-radius: 0;
    max-width: 450px !important;
    @include breakpoint(450px) {
      border-radius: 0.5rem !important;
    }

    .MuiDialogTitle-root {
      @extend .smallShadow;
      width: 100%;
      position: sticky;
      top: 0;
      background-color: white;
    }

    .header {
      margin-bottom: 0;
      padding-bottom: $spacing-md;
      padding-top: $spacing-md;
    }
  }

  .atomic-embed-wrapper {
    width: 100%;
  }

  .atomic-sdk-frame {
    width: 100% !important;
    height: 75vh;
  }
}

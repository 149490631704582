@use "shared/dist/styles/variables/breakpoints.scss" as b;
@use "shared/dist/styles/variables/colors.scss" as c;
@use "shared/dist/styles/variables/spacing.scss" as s;
@use "shared/dist/styles/variables/typography.scss" as t;

.build-your-own-strategy {
  $items-gap: s.$spacing-sm;

  .strategy-option {
    background: c.$background-color;
    border-radius: s.$border-radius-medium;
    display: inline-block;
    padding: 15px;
    position: relative;
    width: 100%;

    &__actions {
      align-items: center;
      display: flex;
      flex-direction: row;
    }
  }

  .build-your-own-grid {
    display: flex;
    flex-direction: column;
    margin-top: s.$spacing-md;

    .grid-header,
    .grid-row {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      > *:not(:last-child) {
        margin-right: $items-gap;
      }

      @include b.breakpoint(b.$screen-md) {
        flex-direction: row;
      }
    }

    .grid-header {
      align-items: flex-start;
      margin-bottom: s.$spacing-sm;

      @include b.breakpoint(b.$screen-md) {
        align-items: center;
        margin-bottom: 0;
      }
    }

    .grid-row {
      border-top: 1px solid c.$color-border;
      flex-direction: row;
      flex-wrap: wrap;
      padding: s.$spacing-sm 0;

      @include b.breakpoint(b.$screen-md) {
        align-items: center;
        border: none;
        flex-wrap: nowrap;
        padding: s.$spacing-2xs;
      }

      .row-name {
        @extend .text-regular;
        flex: 1 1 100%;
        margin-bottom: s.$spacing-sm;

        @include b.breakpoint(b.$screen-md) {
          margin-bottom: 0;
        }
      }
      .row-descriptor {
        display: block;
        margin-top: 0;
        margin-bottom: s.$spacing-xs;

        @include b.breakpoint(b.$screen-md) {
          display: none;
          margin-bottom: 0;
        }
      }
      .row-field {
        display: inline-flex;
        flex-direction: column;
        flex: 0 1 calc(50% - $items-gap);
      }
    }


    .grid-header,
    .grid-row {
      > *:first-child {
        @include b.breakpoint(b.$screen-md) {
          flex: 0 0 40%;
        }
      }
    }

    .grid-row .row-field {
      @include b.breakpoint(b.$screen-md) {
        align-items: center;
        flex: 0 1 calc(29% - ($items-gap * 2));
      }
    }

    .grid-row .percentage-cell {
      align-items: center;
      display: inline-flex;
      flex-direction: row;
      width: 80px;

      @include b.breakpoint(b.$screen-md) {
        flex: 1 0 auto;
        justify-content: center;
        width: 95px;
      }

      > .MuiBox-root {
        text-align: center;
      }
    }

  }


  .form-checkbox {
    margin-left: -5px;
    padding: 5px;
  }

  .form-input {
    width: 105px;
  }

  .display-none {
    display: none;
  }
}
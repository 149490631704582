.personal-details {
  .item {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    flex-direction: column;

    span {
      font-weight: 700;
      margin: 0.5rem 0 0 0;

      & + span {
        font-weight: 400;
      }
    }

    @media(min-width: 768px) {
      flex-direction: row;

      span {
        font-weight: 400;
        margin: 0;
      }
    }
  }
}

.first-home-steps-modal {
  .MuiDialog-container {
    @media (min-width: 769px) {
      max-height: 25rem;
      max-width: 600px;
      min-height: 255px;
    }
  }

  .MuiDialogContent-root.content {
    display: flex;
    flex-direction: column;
  }
}

@import "shared/dist/styles/variables.scss";

@mixin sidebar-item-layout {
  padding: $spacing-sm $spacing-md;
  margin: 0 auto 5px;
  width: 220px;
}

.ff-online-portal {
  .MuiDrawer-root {
    .logo-link {
      @include sidebar-item-layout();
      margin-bottom: 40px;
      padding-bottom: 0;
      padding-top: 0;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      &.bottom {
        bottom: 0;
        padding-bottom: 1.5rem;
        position: absolute;
        width: 100%;

        @media (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
          padding-bottom: 4rem;
        }
        li {
          opacity: 0.5;
          transition: all 200ms ease-in-out;

          padding: 0.5rem;
          margin-bottom: 0;
          a {
            margin-left: 30px;
            font-size: $font-size-sm;
            display: inline;
          }
          &:hover {
            background: none;
            opacity: 1;

            a {
              background-color: transparent;
              color: $color-white;
            }
          }
        }
      }
    }
    li {
      color: $color-white;
      padding: 0;

      .MuiSvgIcon-root,
      a {
        transition: all 200ms ease-in-out;
      }

      a {
        @include sidebar-item-layout();
        align-items: center;
        border-radius: 25px;
        color: $color-white;
        cursor: pointer;
        display: flex;
        margin-bottom: $spacing-sm;
        text-decoration: none;
        &:hover {
          background-color: rgba(0, 0, 0, 0.08);

          .MuiSvgIcon-root {
            fill-opacity: 1;
          }
        }
      }
      .MuiListItemIcon-root {
        margin-right: 14px;
        min-width: unset;
      }
      .MuiSvgIcon-root {
        fill: $color-white;
        fill-opacity: 0.5;
        font-size: 28px;
      }
      .MuiListItemText-root {
        margin: 0;
      }
      &.nav-selected {
        background-color: transparent;

        > a {
          &,
          &:hover {
            background: $background-color;
            color: $color-midnight;
          }
        }

        .MuiSvgIcon-root {
          fill: $color-midnight;
          fill-opacity: 1;
        }
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
}

@use "shared/dist/styles/variables.scss";
@import "./Input"; 
.bank-account-input-section {
  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__input {
    background-color: transparent;
    border: 0;
    color: variables.$c-text-dark;
    font-size: variables.$font-size-sm;
    line-height: variables.$font-line-height-md;
    z-index: 10;
    @include variables.breakpoint(variables.$screen-md) {
      letter-spacing: variables.$spacing-2xs;
    }

    &:focus {
      outline: none;
    }

    &::-ms-clear {
      display: none;
      height: 0;
      width: 0;
    }
  }
  &__label {
    color: variables.$c-text-dark;
    font-size: variables.$font-size-2xs;
    line-height: 0;
  }
}

/* stylelint-disable max-nesting-depth */
@use "shared/dist/styles/variables" as v;

.ff-online-portal .retirement-projector {
  &__fund-option {
    align-items: center;
    background-color: v.$color-white;
    border: 1px solid v.$palette-gray-20;
    border-radius: 27.5px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    padding: v.$spacing-xs;

    > h6 {
      font-size: v.$font-size-sm;
      margin: 0;
      padding-left: v.$spacing-xs;
    }

    > span {
      font-size: v.$font-size-sm;
      color: v.$text-light;
    }

    > button {
      margin-left: auto;
      margin-right: v.$spacing-xs;
    }

    &--error {
      background-color: v.$palette-red-10;
      border-color: v.$palette-red-100;
    }
  }
}

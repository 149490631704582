@import 'shared/dist/styles/variables';

.mobile-date-picker {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid $color-medium-grey;
  border-radius: 8px;
  margin: 0 10px;

  .date-input {
    flex-grow: 1;
    font-weight: 300;
  }

  .MuiSvgIcon-root {
    color: $color-grey;
  }

  .MuiInputBase-root {
    .MuiInputBase-input {
      text-align: center;
      padding: 11px 9px;
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
}

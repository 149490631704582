@use "shared/dist/styles/variables" as *;
.ff-online-portal .zero-balance-message {
    .notification__content {
        padding: 1rem;
        line-height: $font-line-height-lg;

        button>span {
            color: $color-dusk;
            text-decoration: underline;
        }
    }
}
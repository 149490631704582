@use "shared/dist/styles/variables/breakpoints" as b;
@use "shared/dist/styles/variables/colors" as c;
@use "shared/dist/styles/variables/spacing" as s;
@use "shared/dist/styles/variables/typography" as t;

.switch-sub-modal {
  .MuiPaper-root {
    color: c.$c-text-dark;
    font-size: t.$font-size-sm;
  }

  
  .assets-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 s.$spacing-sm;

    @include b.breakpoint(b.$screen-md) {
      margin: 0 s.$spacing-md;
    }

    > * {
      flex: 1 1 100%;

      @include b.breakpoint(b.$screen-md) {
        flex: 0 1 calc(50% - s.$spacing-md)
      }
    }

    .asset-info {
      display: flex;
      margin-bottom: s.$spacing-xs;

      .asset-label {
        flex: 1 1 auto;
        margin: 0;
      }
      .asset-split {
        flex: 0 0 auto;
      }
    }
  }

  .assets-description {
    flex: 1 1 100%;
  }

  canvas {
    border-radius: 6px;
  }

  .tooltip {
    &-label {
      font-size: t.$font-size-md;
      line-height: 1.44;
      font-weight: 600;
      margin-bottom: 10px;
    }
    
    &-icon > svg {
      height: 1.125rem;
    }
  }
  
  .strategy-risk-indicator {
    max-width: 10rem;
  }
  
  .suggested-timeframe {
    border-top: 1px solid c.$c-border;
  }

  .MuiDialog-container {
    li {
      margin: 0.5em 0;
    }
  }
}
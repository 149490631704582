@use "shared/dist/styles/variables.scss" as v;

.ff-online-portal .dashboard-theme-container {
  &:not(.show-header-desktop) {
    @include v.breakpoint(v.$screen-lg) {
      > .main-content.accounts-content-layout {
        .accounts-header {
          height: 0;
        }

        .accounts-header .profile-quick-actions-component {
          display: none;
        }
        .main-content-padding .profile-quick-actions-component {
          display: flex;
        }
      }
    }
  }

  > .main-content {
    &:not(.accounts-content-layout):not(.account-details-content-layout) {
      .accounts-header {
        @include v.mobile() {
          display: none;
        }
      }
    }
  }
}

@use "/src/styles/dashboard-theme.scss" as dashboard;
@use "shared/dist/styles/variables" as *;
@import 'src/styles/mixins.scss';

.switch-sub-modal {
  .MuiPaper-root {
    padding-top: 0;
    padding-bottom: $spacing-md;
    margin: 0;

    @include breakpoint($screen-lg) {
      padding-bottom: $spacing-lg;
    }
    .MuiDialogContent-root {
      padding-top: $spacing-lg;
    }
    .MuiDialogTitle-root {
      @extend .smallShadow;
      width: 100%;
      position: sticky;
      top: 0;
      background-color: white;
    }
    .header {
      margin-bottom: 0;
      padding-top: $spacing-md;
      box-shadow: none;
    }
  }
}

@import "shared/dist/styles/variables";

.reports-container {
  .filters-component {
    .MuiFormControl-root {
      left: -2px;
      top: -18px;
      max-width: 380px;

      @media (max-width: 480px) {
        left: 12px;
        max-width: 92%;
      }
    }
  }
}

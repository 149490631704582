@use "shared/dist/styles/variables" as v;

.ff-online-portal .retirement-projector {
  &__results {
    > p {
      align-items: flex-end;
      display: flex;
      flex-wrap: wrap;
      margin: 0;

      @include v.breakpoint(v.$screen-lg) {
        margin-bottom: v.$spacing-md;
      }
    }

    &__income {
      gap: v.$spacing-xs;

      &__select {
        outline: none;
        color: v.$color-dusk;
        border: 0;
        border-bottom: 1px solid v.$color-dusk;
        background-color: v.$c-background;

        @include v.breakpoint(v.$screen-sm) {
          background-color: v.$color-white;
        }
      }
    }

    &__graph {
      background-color: v.$c-background;
      flex: 1;
      margin-top: v.$spacing-md;

      @include v.breakpoint(v.$screen-sm) {
        margin-bottom: v.$spacing-md;
        margin-top: v.$spacing-xl;
        &:not(.is-loading) {
          background-color: transparent;
        }
      }

      &.is-loading {
        min-height: 255px;
        position: relative;

        @include v.breakpoint(v.$screen-sm) {
          border-radius: v.$spacing-md v.$spacing-md 0 0;
        }
      }

      &__tooltip {
        background-color: v.$color-midnight;
        border-radius: v.$border-radius-small;
        padding: v.$spacing-xs v.$spacing-sm;

        > span {
          color: rgba(255, 255, 255, 0.85);
          font-size: v.$font-size-3xs;
          margin-bottom: v.$spacing-sm;
        }

        > p {
          color: v.$color-white;
          font-weight: 500;
        }
      }

      > hr {
        @include v.breakpoint(v.$screen-lg) {
          display: none;
        }
      }

      .recharts-surface {
        border-radius: v.$border-radius-medium v.$border-radius-medium 0 0;
      }

      .recharts-responsive-container {
        max-height: 300px;
        //Fix for re-sizing issue with recharts https://github.com/recharts/recharts/issues/172
        max-width: 99.9%;
        font-size: v.$font-size-sm;
      }

      .recharts-legend-wrapper {
        display: flex;
        justify-content: center;
      }

      &__legend {
        display: flex;
        align-items: center;
        gap: v.$spacing-sm;

        > hr {
          width: 50px;
          background-color: v.$color-seafoam;
        }

        > p {
          color: v.$palette-gray-110;
        }
      }

      .recharts-legend-item-text {
        color: v.$c-text-dark !important;
      }
    }

    &__loading-cover {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: v.$color-white;
      opacity: 0.7;
      z-index: 1000;

      > div {
        position: absolute;
        top: 40%;
      }
    }

    &__change {
      background-color: v.$c-background;
      padding: v.$spacing-button-md;
      border-radius: v.$border-radius-medium;

      &__contributions {
        &__label {
          font-size: v.$font-size-xs;
          margin-bottom: v.$spacing-2xs;
          line-height: v.$font-line-height-md;
        }
      }
    }

    &__superscript {
      color: v.$c-text-default;
      font-size: v.$font-size-xs;
    }

    &__footnote {
      color: v.$c-text-default;
      font-size: v.$font-size-xs;
      line-height: v.$font-line-height-md;
      max-width: 100%;
    }

    &__large-currency {
      font-size: 26px; // v.$font-size-xl;
      font-weight: 400;
      line-height: v.$font-line-height-md;
    }

    &__actions,
    &__switch-actions {
      display: flex;
      flex-direction: column;

      button {
        margin: 0 v.$spacing-xs;
      }
    }

    &__actions {
      @include v.breakpoint(v.$screen-md) {
        align-items: flex-end;
      }

      @include v.desktop {
        align-items: center;
        flex-direction: row-reverse;

        :first-child {
          margin-left: auto;
        }
      }
    }

    &__switch-actions {
      @include v.breakpoint(v.$screen-md) {
        flex-direction: row-reverse;
        justify-content: flex-end;
        flex-wrap: wrap;
      }
    }
  }
}

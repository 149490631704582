@use "shared/dist/styles/variables.scss" as *;

.account-balance-filters {
    display: flex;
    justify-content: space-between;
    padding: 0 $spacing-sm;

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
        min-width: 40px;
        border: 1px solid transparent;
        border-radius: $border-radius-large;
        font-family: "Graphik", sans-serif;
        font-size: $font-size-sm;
        font-weight: 500;
        background-color: transparent;
        color: $color-smoke;
        transition: 0.3s ease;
        cursor: pointer;

        &:hover {
            background-color: rgba($color-smoke, 0.25);
        }

        @include breakpoint($screen-md) {
            font-size: $font-size-md;
        }
    }

    .selected {
        background-color: $color-midnight;
        color: white;
    }
}

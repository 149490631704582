@import 'shared/dist/styles/variables';

.transactions-filter {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-md) {
    align-items: center;
    flex-direction: row;
  }

  .date-filters-component {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;

    @media (min-width: $screen-md) {
      flex-direction: row;
      flex-grow: 2;
      margin-bottom: inherit;
      width: auto;
    }

    .date-filter {
      &-menu {
        @extend .text-regular;

        margin: 0;
        position: relative;
        width: 100%;

        @media (min-width: $screen-md) {
          margin-right: 1rem;
          width: auto;
        }

        &-button {
          width: 100%;

          .MuiButton-label {
            justify-content: left;

            @media (min-width: $screen-md) {
              justify-content: flex-start;
            }
          }

          .MuiButton-endIcon {
            right: 2rem;
            position: absolute;

            @media (min-width: $screen-md) {
              position: relative;
              right: auto;
            }
          }
        }
      }
    }

    .custom-range-picker-component {
      margin-top: 1rem;
      width: 100%;

      .DateRangePickerInput {
        background: transparent;
        border-color: $color-seafoam;
        border-radius: 4rem;
        color: $color-lake;

        .DateInput {
          background: unset;

          &_input,
          &_input__disabled {
            background-color: unset;
            font-style: normal;
          }
        }

        input {
          padding: 5px;
        }

        &_arrow {
          color: inherit;
          background-color: inherit;
        }

        &__disabled {
          background-color: $color-border;
          border-color: $color-border;
          opacity: 0.5;
        }
      }

      @media (min-width: $screen-sm) {
        margin-top: 0;
        width: auto;
      }

      .mobile-date-picker {
        border-radius: 4rem;
        margin: 0;
      }
    }
    .date-range-filter {
      align-self: flex-end;
      color: $color-black;
      font-size: 1rem;
      font-weight: 300;
      text-align: left;

      @media (min-width: 685px) and (max-width: $screen-md),
        (min-width: 1000px) {
        text-align: center;
      }
    }
  }
  .download-buttons {
    button:first-child {
      margin-right: 1rem;
    }
  }
}

@use "shared/dist/styles/variables.scss";

$button-border-radius: 2rem;

.link,
.button {
  &:hover {
    cursor: pointer;
  }

  &__icon-left,
  &__icon-right {
    align-items: center;
    display: flex;
  }

  &__icon-left {
    margin-right: variables.$spacing-button-2xs;
  }

  &__icon-right {
    margin-left: variables.$spacing-button-2xs;
  }

  &__text {
    font-family: "Graphik", sans-serif;
    font-weight: normal;
  }
}

// We don't want classes nested as link--button--primary so instead of
// normal scss nesting we use an @each for ".link--button" to be the root class
// but nested classes be ".link--primary".
// This is not needed for ".button" but it's here because they share the same styles.
@each $root, $class in ("link--button": link, button: button) {
  .#{$root} {
    align-items: center;
    border: 1px solid transparent;
    box-sizing: border-box;
    color: variables.$c-button-default-dark;
    display: flex;
    flex: 0 0 auto;
    font-size: variables.$font-size-sm;
    justify-content: center;
    line-height: 0.95rem;
    min-width: 135px;
    padding: variables.$spacing-button-xs variables.$spacing-lg;
    -webkit-tap-highlight-color: transparent;
    text-align: left;
    transition-duration: 400ms;
    transition-property: border-color, background, box-shadow;
    transition-timing-function: ease;

    @include variables.breakpoint(variables.$screen-md) {
      text-align: center;
      white-space: nowrap;
    }

    &.focus-visible {
      box-shadow: 0 0 0 1px variables.$color-white, 0 0 0 4px variables.$c-button-focused;
    }

    &:not(.button--link) {
      border-radius: $button-border-radius;
      text-decoration: none;

      .link__text,
      .button__text {
        &,
        &:hover,
        &:visited {
          color: inherit;
        }
      }
    }

    &.#{$class}--primary {
      background: variables.$color-seafoam;
      border-color: variables.$color-seafoam;
      color: variables.$color-lake;

      &:hover,
      &.#{$class}--focused,
      &.focus-visible {
        background: transparent;
        border-color: variables.$color-dusk;
        color: variables.$color-dusk;
      }
    }

    &.#{$class}--secondary {
      background: variables.$color-dusk;
      border-color: variables.$color-dusk;
      color: variables.$color-sky;

      &:hover,
      &.#{$class}--focused,
      &.focus-visible {
        background: transparent;
        border-color: variables.$color-lake;
        color: variables.$color-lake;
      }
    }

    &.#{$class}--white {
      background: variables.$color-seafoam;
      border-color: variables.$color-seafoam;
      color: variables.$color-lake;

      &:hover,
      &.#{$class}--focused,
      &.focus-visible {
        background: transparent;
        border-color: variables.$color-white;
        color: variables.$color-white;
      }
    }

    // TODO: Do this transparent only for buttons (no links)
    &.#{$class}--transparent {
      background: transparent;
      border: transparent;
      color: variables.$color-dusk;

      &:hover,
      &.#{$class}--focused,
      &.focus-visible {
        background-color: variables.$palette-gray-20;
        border-color: variables.$color-lake;
      }
    }

    &.#{$class}--login {
      background-color: variables.$color-sunrise;
      border-color: transparent;

      &:hover,
      &.#{$class}--focused,
      &.focus-visible {
        background-color: variables.$color-sunrise;
        border-color: variables.$color-lake;
      }
    }

    &.#{$class}--outlined {
      &.#{$class}--primary {
        background: transparent;
        border-color: variables.$color-dusk;
        color: variables.$color-dusk;

        &:hover,
        &.#{$class}--focused,
        &.focus-visible {
          background: variables.$color-dusk;
          color: variables.$color-sky;
        }

        &:active {
          box-shadow: none;
        }
      }

      &.#{$class}--white {
        background: transparent;
        border-color: variables.$color-white;
        color: variables.$color-white;

        &:hover,
        &.#{$class}--focused,
        &.focus-visible {
          background: variables.$color-white;
          color: variables.$color-lake;
        }
      }
    }

    &.#{$class}--disabled.#{$root} {
      background-color: variables.$color-smoke-25;
      border-color: transparent;
      color: variables.$color-smoke;
      cursor: default;

      &:hover,
      &.#{$class}--focused,
      &.focus-visible {
        background: none;
        border-color: variables.$color-smoke-25;
        color: variables.$c-button-disabled-dark;
      }
    }

    &.#{$class}--link {
      background-color: transparent;
      border: none;
      color: inherit;
      display: inline-flex;
      font-size: variables.$font-size-sm;
      font-weight: 400;
      min-width: auto;
      padding: 0;

      &.#{$class}--secondary {
        color: variables.$palette-green-80;
      }

      &.#{$class}--white {
        color: variables.$color-white;
      }

      &:hover {
        background-color: transparent;
        box-shadow: none;
      }
    }

    &:not(.button--link) {
      &.#{$class}--sm {
        font-size: variables.$font-size-xs;
        min-width: 120px;
        padding: variables.$spacing-button-xs variables.$spacing-lg;

        > .#{$class}__text {
          line-height: 0.775rem;
        }
        
        .MuiSvgIcon-root {
          font-size: 15px;
        }
      }
      &.#{$class}--md {
        .MuiSvgIcon-root {
          font-size: 17px;
        }
      }

      &.#{$class}--lg {
        font-size: variables.$font-size-md;
        padding: variables.$spacing-button-sm variables.$spacing-button-lg;

        > .#{$class}__text {
          line-height: 1.33rem;
        }

        .MuiSvgIcon-root {
          font-size: 20px;
        }
      }
    }

    &:not(&.#{$class}--button) {
      .loader {
        border: calc(0.2 * var(--loader-size)) solid rgba(0, 68, 124, 0.2);
        border-left-color: variables.$c-button-default-dark;
      }

      &.#{$class}--primary {
        .loader {
          border: calc(0.2 * var(--loader-size)) solid rgba(0, 174, 158, 0.2);
          border-left-color: variables.$c-button-primary-dark;
        }
      }

      &.#{$class}--secondary {
        .loader {
          border: calc(0.2 * var(--loader-size)) solid rgba(234, 18, 18, 0.2);
          border-left-color: variables.$c-button-secondary-dark;
        }
      }

      &.#{$class}--disabled {
        .loader {
          border: calc(0.2 * var(--loader-size)) solid rgba(79, 107, 126, 0.2);
          border-left-color: variables.$c-button-disabled-dark;
        }
      }
    }
  }
}

@each $root, $class in ("button--link": button, link: link) {
  .#{$root} {
    &:not(.link--button) {
      align-items: center;
      display: inline-flex;
      flex-direction: row;
      font-weight: 400;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &.#{$class}--primary {
        color: variables.$color-dusk;
      }

      &.#{$class}--secondary {
        color: variables.$color-seafoam;
      }

      &.#{$class}--white {
        color: variables.$color-white;
      }

      &.#{$class}--sm {
        font-size: variables.$font-size-2xs;
        .MuiSvgIcon-root {
          font-size: 14px;
        }

        @include variables.breakpoint(variables.$screen-md) {
          font-size: variables.$font-size-xs;
        }
      }

      &.#{$class}--md {
        font-size: variables.$font-size-xs;
        .MuiSvgIcon-root {
          font-size: 17px;
        }

        @include variables.breakpoint(variables.$screen-md) {
          font-size: variables.$font-size-sm;
        }
      }

      &.#{$class}--lg {
        font-size: variables.$font-size-sm;
        .MuiSvgIcon-root {
          font-size: 20px;
        }

        @include variables.breakpoint(variables.$screen-md) {
          font-size: variables.$font-size-md;
        }
      }
    }

    &.#{$class}--inline {
      &:not(.link--button) {
        text-decoration: underline;
      }
    }
  }
}

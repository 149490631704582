@use "shared/dist/styles/variables/colors.scss" as c;
@use "shared/dist/styles/variables/typography.scss" as t;

.account-card-returns {
  .return-label {
    color: c.$c-text-default;
    font-size: t.$font-size-2xs;
    font-weight: 600;
  }

  .return-description {
    color: c.$c-text-regular;
  }

  .performance {
    font-weight: 600;
  }
}
@import "shared/dist/styles/variables";

.report-list-component {
  .MuiButton-endIcon {
    @include mobile {
      margin-right: 1rem;
    }
  }

  table th,
  table td {
    &:nth-child(3) {
      @include mobile {
        display: none;
      }
    }
  }
}
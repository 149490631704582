@use "./src/common/current-indicator/CurrentIndicator.scss" as indicator;
@use "shared/dist/styles/variables/breakpoints.scss" as b;

.compare-investment-options {
  max-width: 100%;

  .investment-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 18px;
    padding-top: indicator.$current-indicator-outgap;

    @include b.breakpoint(b.$screen-lg) {
      flex-wrap: nowrap;
    }

    @include b.breakpoint(b.$screen-md) {
      &.has-other-options-select .side-by-side .result-actions {
        // This is needed because current strategies and new strategies have
        // different number of buttons but height should be the same.
        height: 113px;
      }
    }
  }
}

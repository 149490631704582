@use '/src/styles/pretty-card.scss';
@use "../../styles/mixins";
@import 'shared/dist/styles/variables';

.contact-us-popup {
  &-card {
    @extend .pretty-card;
    width: 100%;
    height: 100%;

    @media (min-width: $screen-md) {
      height: 255px;
    }

    .contact {
      &-menu {

        &-list {
          list-style: none;
          padding-inline-start: 0;
        }

        &-item {
          @extend .text-regular;
          font-weight: 600;
          color: $color-dusk;
          text-decoration: none;
          margin-block-start: $spacing-sm;
          pointer-events: inherit;

          .icon {
            display: none;
          }

          .chat-button {
            @extend .text-regular;
            background-color: transparent;
            color: $color-dusk;
            font-weight: 600;
            display: block !important;
            border: none;
            padding: 0;
            cursor: pointer;

            appearance: button;
            -webkit-appearance: button;
          }

          a {
            @extend .text-regular;
            font-weight: 600;
            color: $color-dusk;
            text-decoration: none;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }

    .email-link {
      display: inline-block;
    }

    .postal-address {
      margin-top: 1rem;
      line-height: 1.875 !important;
    }
  }


  &-dialog {
    .MuiDialog-container {

      @media (min-width: $screen-md) {
        max-width: 52rem;
      }
    }
  }

  &-container {
    .MuiGrid-spacing-xs-3 {
      >.MuiGrid-item {
        padding: 10px;
      }
    }

    .MuiTypography {
      &-h5 {
        color: $color-midnight;
        padding: 0.5rem 0 0.5rem;
      }

      &-body2 {
        padding: 0.5rem 0 0.5rem;
        line-height: 2rem;
      }
    }

    .MuiSvgIcon-root {
      fill: $color-midnight;
    }
  }
}
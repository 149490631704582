@use "shared/dist/styles/variables/breakpoints.scss" as b;
@use "shared/dist/styles/variables/colors.scss" as c;
@use "shared/dist/styles/variables/spacing.scss" as s;
@use "shared/dist/styles/variables/typography.scss" as t;

.account-details-return {
  display: flex;
  justify-content: space-between;
  padding: s.$spacing-sm 0;
  width: 100%;

  &:not(:last-of-type) {
    border-bottom: 1px solid c.$color-border;

    @include b.breakpoint(b.$screen-md) {
      border-bottom: unset;
    }
  }

  @include b.breakpoint(b.$screen-md) {
    display: inline-flex;
    flex-direction: column-reverse;
    padding: 0 s.$spacing-lg;
    margin: s.$spacing-md 0;
    width: auto;

    &:first-of-type {
      padding-left: 0;
    }

    &:not(:first-of-type) {
      border-left: 1px solid c.$color-border;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  .currency,
  .performance {
    font-weight: 400;
    font-size: 21px;
    margin-bottom: s.$spacing-2xs;
  }

  .return-label {
    @extend .text-regular;
    color: c.$c-text-default;
    font-weight: 600;
    margin: 0;
  }

  .return-description {
    color: c.$c-text-regular;
    font-weight: 400;
    margin: 0;

    @include b.breakpoint(b.$screen-md) {
      font-size: t.$font-size-sm;
    }
  }

  .tooltip {
    display: flex;
    flex-direction: row;
    align-items: center;

    .tooltip-label {
      .performance {
        padding-bottom: s.$spacing-2xs;
        border-bottom: 2px dashed c.$color-border;
      }
    }
    .tooltip-icon {
      height: 17px;
      width: 17px;

      .MuiSvgIcon-root {
        fill: c.$color-dusk;
      }
    }
  }

  .return-value {
    @include b.breakpoint(b.$screen-md) {
      margin-bottom: s.$spacing-2xs;
    }
  }
}
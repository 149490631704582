@use "shared/dist/styles/variables.scss" as v;
@import './../../styles/mixins.scss';

.copyable-card {
  background: v.$background-color;
  border: none;
  border-radius: v.$border-radius-medium;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  gap: v.$spacing-2xl;
  min-width: 220px;
  text-align: left;
  padding: v.$spacing-xs v.$spacing-md;

  > * {
    align-self: center;
    > p {
      @extend .font-graphik;
      margin: v.$spacing-2xs 0;
    }
  }

  &__icon {
    border-radius: 50%;
    animation: fadeIn 50ms ease forwards;
    &.copied {
      animation: fadeIn 200ms ease forwards;
      fill: v.$color-kawakawa;
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* stylelint-disable max-nesting-depth */
@use "shared/dist/styles/variables";

.ff-online-portal .retirement-projector {
  &__about-you {
    &__form {
      background-color: variables.$c-background;
      border-radius: variables.$border-radius-medium;

      padding-top: variables.$spacing-md;
      padding-bottom: variables.$spacing-md;

      ol {
        list-style: auto;
        padding-inline-start: variables.$spacing-sm;

        @include variables.breakpoint(variables.$screen-md) {
          padding-inline-start: variables.$spacing-md;
        }

        > li {
          padding: 0;

          &::marker {
            @extend .text-regular;
            @extend .text-bold;
          }
        }
      }

      hr {
        background-color: variables.$c-border;
        border: none;
        height: 1px;
      }
    }

    &__section {
      display: flex;
      flex-wrap: wrap;
      gap: 0 variables.$spacing-sm;
    }

    &__radio-group {
      width: 100%;
    }

    &__fund-selection {
      &--error {
        color: variables.$palette-red-100;
        font-size: variables.$font-size-xs;
        line-height: 1;
      }
    }
  }

  &__grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: variables.$spacing-md;

    @include variables.breakpoint(variables.$screen-md) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

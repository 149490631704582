@import 'shared/dist/styles/variables';

.ff-online-portal {
  .main-theme-container {
    position: relative;
    min-height: 100vh;

    &__content {
      background-color: $background-color;
    }

    @media (min-width: 813px) {
      overflow-y: hidden;
    }

    .left-content {
      position: relative;

      @media (max-width: 960px) {
        flex-basis: 100%;
        max-width: 100%;
      }

      @media (min-width: 813px) {
        height: 100vh;
        overflow-y: auto;
      }
    }

    &-image {
      background: url(../assets/images/king-fisher-bg.jpg) center left no-repeat;
      background-size: cover;

      @media (max-width: 960px) {
        display: none;
      }

      &--aon {
        background: url(../assets/images/kotare-bg.jpg) center left no-repeat;
        background-size: cover;
      }
    }

    .text-content-layout {
      height: auto;
      padding: 0;
      width: 100%;
    }

    + .contact-menu-component {
      bottom: 1rem;
      right: 1rem;

      @media (min-width: 601px) {
        bottom: 2rem;
        right: 2rem;
      }

      @media (min-width: 961px) {
        .action-button {
          background: $color-dusk;

          &.toggled {
            background: $color-dusk;
          }
        }

        .MuiSvgIcon-root {
          fill: $color-teal-blue;
        }

        .contact-menu {
          &-item {
            .icon {
              background: $color-dusk;
            }
          }
        }
      }
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      transition: border 0.4s ease;
      border-color: $color-kikorangi;
    }

    .MuiOutlinedInput {
      &-root {
        margin-top: -0.5rem;

        &.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-width: 1px;
        }
      }

      &-input {
        padding: 0.875rem;
        font-size: $font-size-md;
        color: $color-lake;
        border-radius: 25px;
        background-color: #ffffff;
      }

      &-notchedOutline {
        border-radius: 25px;
        border-color: $color-seafoam;
      }

      &-adornedEnd {
        border-radius: 25px;
        background-color: white;
      }
    }

    .MuiInputAdornment-positionEnd {
      opacity: 0.8;
    }
  }

  .login-component {
    left: 50%;
    max-width: 30rem;
    padding: 1.5rem;
    position: absolute;
    top: calc(50%);
    transform: translate(-50%, -50%);
    width: 100%;

    &.full-content-layout {
      height: auto;
      margin: 0;
      max-width: 100%;
      position: inherit;
      padding: 1.5rem 1.5rem 7rem;
      width: 100%;

      @media (min-width: 961px) {
        height: 100vh;
        overflow-y: auto;
      }

      @media (min-width: 1200px) and (orientation: landscape),
        (min-width: 960px) and (orientation: landscape),
        (min-width: 736px) and (orientation: landscape),
        (min-width: 768px) {
        padding: 4rem;
      }

      @media (min-width: 1400px) and (orientation: landscape) {
        padding: 6rem;
      }

      @media (min-width: 1600px) and (orientation: landscape) {
        padding: 8rem;
      }

      @media (min-width: 1800px) and (orientation: landscape) {
        padding: 10rem;
      }

      .text-content-layout {
        margin-top: -3.5rem;
      }
    }

    .logo {
      @media (max-width: $screen-md) {
        margin: 3rem 0 1.5rem;
      }

      width: 200px;
      margin-left: -3px;
    }

    .MuiTypography-h4 {
      @media (max-width: $screen-md) {
        font-size: $font-size-xl;
      }

      margin-bottom: 0.5rem;
      color: $color-midnight;
    }

    p {
      margin-top: 0;
    }

    .login-pin-loading-spinner {
      position: absolute;
      bottom: -35px;
    }

    #ffm-alert {
      .MuiSnackbarContent-root {
        margin: 1.5rem 0;
      }
      .notification--error {
        margin: 1rem 0;
      }
    }

    .MuiTypography-body1 {
      color: #4f6b7e;
      font-size: $font-size-sm;
    }

    .form {
      .MuiButton-contained {
        box-shadow: none;
      }

      &.login-form {
        position: relative;

        .forgot-password-link {
          position: absolute;
          right: 0;
          top: calc(7.75rem - 1px);
        }

        .login-field-group:first-child .MuiFormHelperText-root.Mui-error {
          color: red;
          margin-bottom: -1.25rem;
        }

        .password-field-group {
          margin-top: 43px;
        }
      }

      .action-group {
        .MuiLink-root {
          display: block;
          margin-top: 1.25rem;
        }
      }

      .login-field-group {
        &__label {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    // TODO: replace with clickable button on rebranding merge
    .MuiButton-contained {
      @extend .h6;
      padding: 0 2rem;
      color: $color-lake;
      background-color: $color-seafoam;
      border-radius: 25px;
      box-shadow: none;
      min-height: 3.125rem;
      margin-top: 1.5rem;
      font-weight: 400 !important;
      text-transform: none;
      border: 1px solid transparent;
      transition: border-color background box-shadow 0.4s ease;

      &:hover {
        background-color: $color-white;
        color: $color-dusk;
        border-color: $color-dusk;
      }

      &.Mui-disabled {
        background-color: transparent !important;
        color: $color-dusk !important;
        border-color: $color-dusk !important;
      }
    }

    .MuiTypography-h6 {
      color: $color-midnight;
      font-weight: 400;
      margin-bottom: 1rem;
      text-transform: inherit;
    }

    .register-login-link-box {
      margin-top: 1.875rem;
      text-align: center;
    }

    .back-to-login {
      display: flex;
      align-items: center;

      svg {
        font-size: 18px;
        margin-right: 0.5rem;
      }
    }

    @media (min-width: 590px) and (max-width: 960px) {
      margin: 3rem 0 6rem;
    }

    @media (max-width: 959px), (max-height: 700px) {
      position: relative;
      top: 0;
      transform: translate(-50%, 0);
    }

    @media (max-width: 959px) {
      margin: 1.75rem 0 0rem;
    }

    @media (max-width: 520px) {
      margin-top: 0;

      .MuiTypography-h1 {
        font-size: 1.75em;
        margin-bottom: 1.75rem;
        margin-top: -0.25rem;
      }
    }

    .MuiSnackbarContent-message {
      padding-left: 0 !important;
      padding-right: 1.5rem;
    }
  }

  .not-found-component {
    .MuiTypography-body1 {
      margin: 1rem 0;
    }

    a {
      text-decoration: underline;
    }
  }

  .MuiLink-root {
    color: $color-dusk;
  }

  .footer-links {
    padding: 2rem 0 1.5rem;

    a {
      text-decoration: underline;
    }
  }

  @keyframes loadingSpinner {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

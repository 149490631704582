@use "sass:math";
@use "shared/dist/styles/variables.scss";

$c-slider-rail: variables.$palette-gray-20;
$c-slider-rail-active: variables.$color-dusk;

$c-slider-track: variables.$color-dusk;

$c-slider-thumb: variables.$color-dusk;

$rail-height: 4px;
$thumb-diameter: 15px;

.slider-v2 {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: $thumb-diameter;
  position: relative;
  width: 60%;

  &__rail {
    background: $c-slider-rail;
    border-radius: math.div($rail-height, 2);
    box-sizing: border-box;
    height: $rail-height;
    margin: 0 math.div($thumb-diameter, 2);
    width: 100%;

    &--active {
      background: $c-slider-rail-active;
    }
  }

  &__track {
    background: $c-slider-track;
    border-radius: math.div($rail-height, 2);
    height: $rail-height;
    left: 0;
    margin: 0 math.div($thumb-diameter, 2);
    position: absolute;
    top: math.div(($thumb-diameter - $rail-height), 2);
  }

  &__thumb {
    background: $c-slider-thumb;
    border-radius: 50%;
    height: $thumb-diameter;
    left: 0;
    position: absolute;
    top: 0;

    transition: box-shadow 0.3s ease;
    width: $thumb-diameter;

    &--active,
    &:hover {
      box-shadow: 0 0 0 2px $c-slider-thumb, 0 0 0 5px rgba($c-slider-thumb, 0.15);
      cursor: pointer;
    }
  }
}

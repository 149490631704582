@import "shared/dist/styles/variables.scss";

.MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 25px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: $color-seafoam !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $color-blue;
  border-width: 1px;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  color: $color-lake;
  padding-left: 18px;

  &:focus {
    border-radius: 25px;
  }
}

.MuiFormLabel-root.Mui-focused {
  color: $color-blue;
}

.MuiFormLabel-root.MuiFormLabel-root {
  transform: translate(18px, 20px) scale(1);
  background-color: transparent;
  padding: 0 4px;
}

.MuiFormLabel-root.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background-color: transparent;
  transform: translate(10px, -6px) scale(0.75);
  color: $color-smoke;
}
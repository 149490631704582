@import "shared/dist/styles/variables";

html {
  box-sizing: border-box;
}
html, body {
  overflow-x: hidden;
  position: relative;

  @media (min-width: 813px) {
    overflow: hidden;
  }
}

html, body, #root, .app {
  height: 100%;
}

body {
  display: block;
  font-family: $graphik-family;
  font-size: 16px;
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: 100%;
}
*, *::before, *::after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
}
a, button {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
b,strong {
  font-weight: 600;
}
hr {
  background-color: $color-border;
  border: none;
  height: 1px;
  margin: $spacing-md 0;
}
@import 'main-theme';
@import 'dashboard-theme';
@import 'date-range-picker';
@import 'text-content-layout';
@import 'mui-components/all';

.rename-account-modal {
    &__content {
        display: flex;
        flex-direction: column;

        label {
            display: flex;
            flex-direction: column;
        }

        button {
            align-self: flex-end;
        }
    }
}

@import 'shared/dist/styles/variables';

.account-investment-tab-component {
  position: relative;

  .loading-spinner {
    position: absolute;
    top: 4rem;
  }
  .MuiPaper-root {
    padding: 1rem;

    @media (min-width: 600px) {
      flex-grow: 1;
      padding: 1.5rem;
    }
  }
  .MuiTypography-h6 {
    color: $color-blue;
    font-weight: 400;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }
}
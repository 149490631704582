@import "./../../styles/mixins.scss";
@import 'shared/dist/styles/variables';
@import 'shared/dist/styles/variables/breakpoints.scss';
@import "shared/dist/styles/variables/typography.scss";

$border-radius: 10px;
.dashboard-theme-container .account-details-information {
  .current-balance-title {
    color: $color-midnight;
    display: none;
    margin-bottom: $spacing-xs;
    text-transform: uppercase;
    font-weight: 300;

    @include breakpoint($screen-md) {
      display: block;
    }
  }

  .details-label {
    @extend .text-large;
    color: $color-midnight;
    font-weight: 600;
    margin: 0;

    @include breakpoint($screen-md) {
      font-size: $font-size-md;
    }
  }

  .details-description {
    color: $color-smoke;
    margin: 0;
  }

  &__total {
    margin-bottom: $spacing-xs;
    margin-top: 0;

    .currency__total {
      font-size: 28px;
      font-weight: 300;
      line-height: 1;

      @include breakpoint($screen-md) {
        font-size: 48px;
      }
    }

    .currency__dollar-sign {
      font-size: $font-size-sm;
      margin-top: 3px;
      @include breakpoint($screen-md) {
        font-size: $font-size-xl;
        margin-top: 5px;
      }
    }
  }

  &__balance {
    display: flex;
    flex-direction: row;

    &-information {
      flex: 1 1 auto;
    }

    &-actions {
      align-items: flex-end;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;

      .view-unit-information {
        color: $color-dusk;
        display: none;
        padding: 0;

        @include breakpoint($screen-md) {
          display: flex;
        }
        
        .MuiSvgIcon-root {
          font-size: 16px;
        }
      }
    }
  }
  
  .MuiDivider-root {
    margin: 20px 0;
  }

  .account-details__returns {
    border-top: 1px solid $color-border;
    color: $color-smoke;
    display: block;
    margin-top: $spacing-md;
    width: 100%;

    &__loading-shimmer {
      @include shimmer;
      width: 5rem;
      height: 10px;
    }

    .MuiGrid-item {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: auto;
    }
  }

  // Legacy styles that will be removed along with account-details-information__numbers
  &__numbers {
    padding-bottom: $spacing-md;

    .flex-row {
      justify-content: space-between;
    }
    
    &__loading-shimmer {
      @include shimmer;
      width: 5rem;
      height: 10px;
    }
    .currency {
      @extend .text-large;
      font-weight: 600;
    }
  }
}

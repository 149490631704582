@use "shared/dist/styles/variables/funds.scss";
@import "shared/dist/styles/variables";
@import "./../../styles/mixins.scss";
@import "shared/dist/styles/variables/typography.scss";

@mixin card-size {
  width: 100%;

  @include breakpoint($screen-xl) {
    max-width: 400px;
  }
}

.accounts-overview-component {
  $border-radius: 10px;

  margin: 0 1.5rem;

  @include breakpoint($screen-md) {
    margin: 0 3.75rem;
  }

  a {
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
  }

  h6 {
    margin: 3.2rem 0 0.625rem;
  }

  .loading-spinner {
    color: white;
  }

  .accounts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-gap: 0.75em;

    @include breakpoint($screen-md) {
      grid-gap: 1.625rem;
    }
  }

  .account-card {
    @include elavation;
    @include card-size;
    background: $color-dusk-10;
    border-radius: $border-radius;

    .account {
      &-default {
        background-color: $color-lake;
      }

      &-kiwisaver,
      &-fisher-funds-two {
        @include funds.kiwisaver-colors;
      }

      &-kiwisaver-plan {
        @include funds.kiwisaver-plan-colors;
      }

      &-managed-funds {
        @include funds.managed-funds-colors;
      }

      &-investment-funds {
        @include funds.investment-funds-colors;
      }

      &-premium-service {
        @include funds.premium-service-colors;

        .type-icon svg path {
          stroke: $color-premium-service-text;
        }
      }

      &-lifesaver {
        @include funds.lifesaver-colors;

        .type-icon svg path {
          stroke: $color-lifesaver-text;
        }
      }

      &-investment-series {
        @include funds.investment-series-colors;

        .type-icon svg path {
          stroke: $color-investment-series-text;
        }
      }

      &-futureplan {
        @include funds.futureplan-colors;

        .type-icon svg path {
          stroke: $color-future-plan-text;
        }
      }
    }

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      border-radius: $border-radius;
      color: $color-white;

      .MuiTypography-body1 {
        @extend .text-small;
      }
    }

    .MuiCard-root {
      flex: 1;
    }

    .MuiCard-root,
    &__header {
      width: 100%;
      padding: 0.6rem 1.3rem;
    }

    .MuiDivider-root {
      margin: 0.5rem 0;
    }

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0.8rem;
      padding-bottom: 0;
      white-space: nowrap;

      &__details {
        min-width: 0;
      }

      &__name {
        display: flex;

        p {
          @extend .text-regular;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &__button {
        max-height: 20px;
      }

      &__type {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        min-width: 30%;
        margin-left: 2rem;
      }

      &__product {
        margin-left: 0.2rem;
      }
    }

    &__total {
      .currency__total {
        font-size: $font-size-xl;
        font-weight: 300;
      }

      .currency__dollar-sign {
        @extend .text-large;
        margin-top: 0.15rem;
      }
    }

    &__total-label {
      font-size: $font-size-xs;
      color: $color-smoke;
    }

    &__returns {
      font-size: $font-size-xs;
      color: $color-smoke;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: 0.6rem;

      &__label {
        color: $color-midnight;
        font-weight: 600;
      }

      .currency {
        font-weight: 600;
      }

      &__loading-shimmer {
        @include shimmer;
        width: 5rem;
        height: 10px;
      }

      .MuiGrid-item {
        width: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .new-account-card {
    @include elavation;
    @include card-size;
    background-color: $color-white;
    border: 1px dashed $color-border;
    border-radius: $border-radius;
    color: $color-dusk;
    display: flex;
    flex-direction: column;

    &__label {
      margin-top: 0.5rem;
    }
  }
}

.new-account-modal {
  .MuiPaper-root.MuiDialog-paperFullWidth {
    padding: 32px 0 16px 0;
    max-width: 800px;
  }

  .MuiGrid-root {
    &.MuiGrid-container {
      margin-bottom: 0;
    }
  }

  .MuiTypography {
    text-align: left;

    &-h5,
    &-h6 {
      font-weight: 600;
      color: $color-black;

      &.kiwisaver {
        color: $color-dusk;
      }
    }

    &-h5 {
      padding: 0 24px;
    }

    &-body1 {
      margin-bottom: 16px;
      color: $color-midnight;
      @extend .text-regular;
    }
  }

  &-content-main {
    margin-bottom: 16px;

    .option-card {
      background-color: $background-color;
      border-top: 5px solid $color-midnight;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding: 16px 16px 8px 16px;

      &.kiwisaver {
        border-color: $color-dusk;
      }
    }
  }

  .close-button {
    position: absolute;
    right: 1px;
    top: 16px;
  }

  .MuiCardActions-root:hover {
    cursor: pointer;
  }

  .MuiCardActions-root,
  .MuiCardContent-root:last-child {
    padding: 0;
  }
}

@import "shared/dist/styles/variables";
@import "../../styles/mixins";

.government-contribution {
  color: $color-midnight;
  font-size: $font-size-xs;
  line-height: 1rem;

  &__content {
    padding: 1rem 1.3rem;
    display: flex;
    flex-direction: column;

    &__labels {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__info {
      height: inherit;
      margin-left: 0.3rem;
    }
    svg {
      fill: $color-dusk;
    }
  }

  &__numbers,
  &__as-at-date {
    margin-top: 0.1rem;
  }

  &__numbers {
    &__max {
      color: $color-smoke;
    }
  }

  &__as-at-date {
    color: $color-smoke;
    font-size: $font-size-3xs;
  }

  &__progress-bar {
    margin-top: 0.6rem;
  }
}

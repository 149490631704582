@use "shared/dist/styles/variables/breakpoints.scss" as b;
@use "/src/components/accounts-header/AccountsHeader.scss" as header;

$mobile-margin: 15px;
$desktop-margin: 40px;

.dashboard-theme-container {
  #ffm-alert {
    left: $mobile-margin;
    margin: 0;
    position: fixed;
    right: $mobile-margin;
    top: calc(header.$accounts-header-height-sm + $mobile-margin);
    transform: translateY(-200%);
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.25, 1.35);

    @include b.breakpoint(b.$screen-md) {
      left: unset;
      right: $desktop-margin;
      top: calc(header.$accounts-header-height-md + $desktop-margin);
    }

    &.ffm-alert__active {
      transform: translateY(0);
    }
  }
}

.main-theme-container {
  #ffm-alert {
    margin-bottom: 2rem;
  }
}

@use "shared/dist/styles/variables" as *;

%risk-tooltiptext {
  color: $color-white;
  font-size: $font-size-xs;
  line-height: $font-line-height-md;
}

.risk-tooltip {
  &__container {
    padding: $spacing-xs;
  }

  &__description {
    @extend %risk-tooltiptext;
    margin-top: $spacing-sm;
  }

  &__title {
    @extend %risk-tooltiptext;
    margin-bottom: $spacing-sm;
  }
}

.risk-chart {
  $self: &;

  &__chart-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
  }

  &__option {
    align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
    background-color: $palette-gray-100;
    border-color: $palette-gray-80;
    border-style: solid;
    border-width: 2px 0;
    color: $palette-gray-40;

    font-size: $font-size-xs;
    line-height: $font-line-height-lg;
    padding: $spacing-xs;
  }

  &__value {
    @extend .risk-chart__option;
    color: $color-white;
    font-size: $font-size-md;
    position: relative;
  }

  &__return-labels,
  &__risk-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__text {
    color: $color-white;
    font-size: $font-size-2xs;

    &--right {
      @extend .risk-chart__text;
      text-align: right;
    }

    &--bold {
      @extend .risk-chart__text;
      font-weight: 600;
    }
  }

  &__arrow-down {
    color: $palette-gray-80;
    left: 0;

    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-6px);
  }

  &__arrow-up {
    bottom: 0;
    color: $palette-gray-80;
    left: 0;

    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    transform: translateY(6px);
  }

  &--light {
    #{$self}__text {
      color: $color-smoke;
    }

    #{$self}__option {
      background-color: transparent;
      border-color: $color-seafoam;
      color: $color-midnight;
    }

    #{$self}__arrow-down, #{$self}__arrow-up {
      color: $color-midnight;
    }
  }
}

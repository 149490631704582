@use "shared/dist/styles/variables/breakpoints.scss" as b;
@use "shared/dist/styles/variables/colors.scss" as c;
@use "shared/dist/styles/variables/spacing.scss" as s;
@use "shared/dist/styles/variables/typography.scss" as t;

.different-option-selector {
  .option-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .investment-option {
    background: c.$background-color;
    border-radius: s.$border-radius-medium;
    display: inline-block;
    padding: 15px;
    position: relative;
    width: 100%;
    @include b.breakpoint(b.$screen-sm) {
      width: calc(50% - 10px);
    }

    &--is-current {
      border: 2px solid c.$color-dusk;

      $card-overlap: 7px;
    }

    &__actions {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: s.$spacing-lg;
      justify-content: space-between;
    }
  }
}
@use "shared/dist/styles/variables.scss" as *;
@import "./Input";

.bank-account-input {
  &__container {
    @include input-color;
    @include transition(150ms, ease-in-out, border, box-shadow);
    background-color: $c-background;
    border-radius: 25px;
    cursor: text;
    display: flex;
    flex-direction: row;
    margin-top: $spacing-sm;
    padding: $spacing-xs $spacing-md 0;

    &.focus-within {
      border-color: $color-kikorangi;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
      outline: 2px solid transparent;
      outline-offset: 2px;
    }

    &:focus-within {
      border-color: $color-kikorangi;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
      outline: 2px solid transparent;
      outline-offset: 2px;
    }

    &--error {
      @extend .bank-account-input__container;
      @include input-error;
    }
  }

  &__section-separator {
    color: $c-text-dark;
    display: block;
    font-weight: 700;
    margin: 0 $spacing-button-sm 0 $spacing-button-2xs;
    text-align: center;

    &--dark {
      @extend .bank-account-input__section-separator;
      color: $c-text-dark;
    }
  }
}

.nominated-bank-account-modal {
  &__modal-content {
    @include breakpoint($screen-md) {
      padding: $spacing-lg $spacing-lg $spacing-sm;
    }
  }

  &__input-wrapper {
    margin-top: $spacing-md;
  }

  &__inputs-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint($screen-md) {
      width: 100%;
    }
  }

  &__tooltip-button {
    color: $c-text-regular;
    display: inline;

    .link-button__text {
      line-height: 1;
    }
  }

  &__button-container {
    background-color: $color-white;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: $spacing-md $spacing-lg;
  }
}
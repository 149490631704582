@import "shared/dist/styles/variables.scss";

.dashboard-theme-container .account-details-component {
  $padding-y-mobile: 20px;
  $padding-y-desktop: 30px;
  .account-details-header,
  .account-details-content {
    padding-left: $padding-y-mobile;
    padding-right: $padding-y-mobile;

    @include breakpoint($screen-md) {
      padding-left: $padding-y-desktop;
      padding-right: $padding-y-desktop;
    }
  }

  $overlap-size: 50px;
  .account-details-header {
    padding-bottom: calc(#{$overlap-size} + #{$padding-y-mobile});

    @include breakpoint($screen-md) {
      padding-bottom: calc(#{$overlap-size} + #{$padding-y-desktop});
    }
  }
  .account-details-content {
    margin-top: -#{$overlap-size};
    padding-bottom: 15px;
    padding-top: 15px;

    @include breakpoint($screen-md) {
      padding-bottom: 30px;
      padding-top: 30px;
    }
  }
  &.main-content-padding {
    @media (max-width: $screen-md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@use 'shared/dist/styles/variables.scss' as v;

.withdraw-funds-modal {
  .loading-spinner {
    height: 20px;
    width: 20px;

    > .MuiCircularProgress-root {
      height: inherit !important;
      width: inherit !important;
    }
  }

  .form {
    &-container {
      background: v.$palette-blue-10;
      border-radius: v.$border-radius-medium;
      padding: 0;

      @include v.breakpoint(v.$screen-md) {
        padding: v.$spacing-sm;
      }

      &-section {
        h6 {
          color: v.$color-midnight;
          margin-left: v.$spacing-md;
        }

        &-sub-label {
          margin: 0 0 v.$spacing-sm;
          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &.withdrawal,
        &.bank-details {
          border-radius: v.$border-radius-medium;
          background: v.$color-white;
          padding: 0 0 v.$spacing-md 0;
          margin: 0 v.$spacing-md;

          hr {
            border-top: 1px solid v.$palette-gray-10;
            margin: v.$spacing-sm v.$spacing-md;
          }

          label {
            @extend .text-regular;
            color: v.$color-midnight;
            margin-top: v.$spacing-md;
          }
        }
      }
      .action-buttons {
        display: flex; 
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      sup {
        font-weight: 400 !important;
      }
    }
  }
}

@import "shared/dist/styles/variables";

.performance {
  align-items: center;
  display: inline-flex;
  flex-direction: row;

  &-black {
    color: $color-midnight;
  }
  &-red {
    color: $color-red;
  }

  &-positive {
    color: $color-kawakawa;
    stroke: $color-kawakawa;
  }

  &-negative {
    color: $color-puriri;
    stroke: $color-puriri;
  }

  &__trend-icon {
    font-size: $font-size-xs;
    margin-left: 0.5rem;

    path {
      fill: none;
    }
  }

  &__total {
    display: flex;
    flex-direction: row;
  }
}

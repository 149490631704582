@import 'shared/dist/styles/variables';

#redirecting-container  {
  background-image: url("../../assets/images/king-fisher-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.redirecting-circle-bg {
  position: absolute;
  circle {
    animation: none;
    stroke-dasharray: none;
    color: $color-light-grey;
  }
}

@use "/src/styles/dashboard-theme.scss" as dashboard;
@use "shared/dist/styles/variables" as *;

.material-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiDialog-paperFullScreen {
    max-height: 100%;
  }

  a {
    color: $color-dusk;
  }

  &.third-party-modal {
    .MuiDialog-container .MuiPaper-root {
      height: 100%;
    }

    @include breakpoint($screen-md) {
      .MuiDialog-container {
        height: 90%;
        max-height: 1024px;

        .MuiPaper-root {
          max-width: 1024px;
        }
      }
    }
  }

  &.auto-height {
    .MuiDialog-paperFullScreen {
      @media (min-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1),
        (min-device-width: 768px) and (min-resolution: 192dpi),
        (min-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2),
        (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
        height: auto;
      }
    }
  }

  .MuiPaper-root {
    padding: $spacing-md 0;

    @include breakpoint($screen-lg) {
      padding: $spacing-lg 0;
    }
  }

  .header,
  .content {
    padding: 0 $spacing-md;

    @include breakpoint($screen-lg) {
      padding: 0 $spacing-lg;
    }
  }

  .MuiDialogContent-root:not(.content) {
    padding: 0;
  }

  .content {
    @extend .text-regular;
    color: $c-text-dark;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
  }

  .close-button .MuiSvgIcon-root {
    transform: scale(0.8);
    fill: $color-midnight;
  }

  .iframe {
    @media (max-width: 600px) {
      margin: 0 -1rem;
    }

    iframe {
      border: 0;
      height: 100%;
      width: 100%;
    }
  }

  .MuiDialog-container {
    height: 100%;
    width: 100%;

    @include breakpoint($screen-md) {
      max-height: calc(100% - ($spacing-xl * 2));
    }

    .MuiPaper-root {
      border-radius: 0;
      height: auto;
      max-width: 800px;

      @include breakpoint($screen-md) {
        border-radius: 0.5rem;
      }
    }
  }

  .header {
    margin-bottom: $spacing-md;

    .close-button {
      padding: $spacing-xs;
      position: absolute;
      right: 1px;
      top: 1px;

      &:hover {
        border: 0;
      }
    }
  }

  &.auth-modal,
  &.rename-account-modal {
    .MuiDialog-container {
      max-width: 470px;

      .MuiPaper-root {
        border-radius: 0.5rem;
      }
    }
  }
}

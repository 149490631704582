@import 'shared/dist/styles/variables';

.info-message-component {
  background-color: $color-light-teal-blue;
  border: 1px solid $color-teal-blue;
  border-radius: 4px;
  display: flex;
  padding: 0.875rem;

  .icon {
    min-width: 2rem;
    position: relative;
  }
  .MuiSvgIcon-root {
    fill: $color-teal-blue;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .message {
    font-size: 0.875em;
    font-weight: 300;
  }
}

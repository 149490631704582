@import "shared/dist/styles/variables";
@import "../../styles/mixins";

.my-details-container {
  background-color: $background-color;
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include breakpoint($screen-lg) {
      flex-direction: row;
    }

    .left-content,
    .right-content {
      margin-bottom: 20px;

      flex: 1 1 100%;
      @include breakpoint($screen-lg) {
        flex-grow: 0;
        flex-basis: calc(50% - 10px);
      }
    }
  }

  .loading-spinner {
    text-align: center;
  }

  .MuiGrid-spacing-xs-2 {
    @media (max-width: $screen-sm) {
      margin-top: 0;

      .MuiGrid-item:first-child {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }
  .notification {
    background-color: white;

    @media (min-width: $screen-sm) {
      background-color: $background-color;
    }
  }

  .my-details-card {
    @extend .smallShadow;
  }

  .MuiPaper-root {
    padding: 1rem;

    @include breakpoint($screen-md) {
      flex-grow: 1;
      padding: 1.5rem;
    }
    @include breakpoint($screen-lg) {
      padding: 2rem;
    }

    .MuiBox-root {
      padding: 2rem 0;
    }
    &:not(.security-tabs-content) {
      &:not(.form-message-component) {
        &:not(.notification) {
          @media (max-width: $screen-sm) {
            background-color: $background-color;
            border-right: 4px solid $color-light-grey;
            border-radius: 0;
            top: 0;
            left: -480px;
            overflow-x: hidden;
            overflow-y: auto;
            height: calc(100% - 4rem);
            position: absolute;
            transition: all 300ms ease-in-out;
            width: 100%;
            z-index: 0;

            &.active-content {
              left: 0;
              z-index: 1;
            }
            .MuiTypography-h6 {
              position: relative;
              text-align: center;
            }
          }
        }
      }
    }
    @media (max-width: $screen-sm) {
      &:not(.notification) {
        border-radius: 0;
        box-shadow: none;
        padding: 1.5rem 1rem 1.75rem;

        .form-message-component {
          &.success,
          &.error {
            padding: 0 !important;
            border-radius: 4px;
          }
        }
      }
    }
  }
  .MuiTypography-h5 {
    color: $color-midnight;
    margin-bottom: 1.5rem;
  }
  .MuiTypography-h6 {
    color: $color-midnight;
  }
  .MuiTypography-caption {
    color: $color-smoke;
    margin-bottom: 0.25rem;
    white-space: nowrap;
  }
  .my-details-content {
    background-color: $background-color;
    @media (min-width: $screen-sm) {
      background-color: #fff;
    }
    @media (max-width: $screen-sm) {
      opacity: 1 !important;
      left: 0 !important;
    }
    .MuiTypography-h6 {
      text-align: left !important;
    }
    .my-details-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;
      flex-direction: column;

      @media (min-width: 685px) and (max-width: 1180px) {
        flex-direction: row;
      }

      .left-content-details,
      .right-content-details {
        flex-grow: 1;
      }
      .left-content-details {
        padding-bottom: 0.75rem;
        padding-right: 1rem;
      }
      .info-message-component {
        margin-top: 2rem;
      }
    }
  }
  .pir-details-content {
    .pir-options-menu-wrapper {
      .MuiButton-root {
        font-size: 0.85em;
        font-weight: 300;
        padding: 0;
        text-transform: inherit;

        @media (min-width: 769px) {
          font-size: 0.875em;
        }
        .MuiButton-endIcon {
          position: relative;
          top: -1px;
        }
      }
    }
    .action-buttons {
      margin-top: 1.5rem;
      display: flex;
      justify-content: space-between;
    }
  }
  .security-tabs-content {
    padding-top: 1.8rem;

    /* @media (min-width: 1180px) {
      .MuiBox-root {
        padding-top: 4rem !important;
      }
    }*/
    > .MuiPaper-root {
      padding: 0;
    }
    .MuiBox-root {
      padding: 2rem 0 1rem;
    }
    @media (max-width: $screen-sm) {
      margin: 0.5rem 0 0;
      padding: 0;

      .MuiTabs-root {
        display: none;
      }
      .MuiPaper-root {
        &:not(.notification) {
          padding: 1.5rem 1rem 8rem;
        }
      }
      .MuiBox-root {
        padding-top: 0;
      }
      .security-tab-panel-content {
        display: block;

        &.hidden {
          display: none;
        }
      }
    }
  }
  .my-details-menu {
    list-style: none;
    padding: 0;

    li {
      background-color: $color-white;
      border-radius: 5px;

      .MuiDivider-root {
        margin-left: 1.5rem;
      }

      .MuiLink-root {
        color: $color-midnight;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0.8rem 1rem 1.5rem;

        .MuiSvgIcon-root {
          color: rgba(0, 0, 0, 0.54);
          float: right;
          font-size: 1.5rem;
        }

        .MuiTypography-caption {
          text-transform: uppercase;
          color: $color-smoke;
          margin-left: 1.5rem;
        }

        &.logout-button {
          justify-content: flex-start;
          color: $color-puriri;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .back-button {
    left: -1rem;
    position: absolute;
    top: -0.95rem;
  }
}
.pir-options-menu {
  top: 0.5rem !important;

  @media (min-width: 685px) and (max-width: $screen-md), (min-width: 1000px) {
    display: none;
  }
  .MuiListItem-button {
    font-size: 0.875em;
    font-weight: 300;
    min-height: 2rem;
  }
}

@use "shared/dist/styles/variables" as *;
@use "shared/dist/styles/variables/typography.scss";

.ff-online-portal .next-best-action-component {
  margin-top: 5rem;

  .title {
    color: $color-midnight;
  }

  .description {
    @extend .text-large;
    color: $text-dark;
  }

  .MuiPaper-root {
    @media (min-width: 600px) and (max-width: 768px), (min-width: 1000px) {
      min-height: 16rem;
    }

    &.has-wing-motif {
      display: flex;
      flex-direction: row;
      gap: 50px;
    }

    &:not(.has-wing-motif) {
      padding: 1.25rem 1.5rem 2.5rem;

      @media (min-width: 600px) and (max-width: 768px), (min-width: 1000px) {
        padding: 2.5rem 2rem 2.5rem;
      }
    }

    .action__wing-motif {
      display: none;
      flex: 0 0 330px;
      position: relative;

      @include breakpoint($screen-lg) {
        display: block;
      }

      .top-wing,
      .bottom-wing {
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        bottom: 0;
        height: 100%;
        position: absolute;
        right: 0;
        width: 100%;
        border-radius: 10px 0 0 10px;
      }

      .top-wing {
        background-image: none;
        mask-position: right;
        mask-repeat: no-repeat;
        mask-size: cover;
        z-index: 2;
        &::after {
          content: "";
          display: block;
          height: 100%;
        }
      }

      .bottom-wing {
        background-color: transparent; // We want to show the bird image
        z-index: 1;
      }

      &.managed-funds {
        .top-wing {
          mask-image: url("./../../assets/images/managed-funds-top-wing.svg");
          &::after {
            background-color: $color-midnight;
          }
        }
        .bottom-wing {
          background-image: url("./../../assets/images/managed-funds-bottom-wing.svg");
        }
      }

      &.kiwisaver {
        .top-wing {
          mask-image: url("./../../assets/images/kiwisaver-top-wing.svg");
          &::after {
            background-color: $color-dusk;
          }
        }
        .bottom-wing {
          background-image: url("./../../assets/images/kiwisaver-bottom-wing.svg");
        }
      }
    }

    .action__content {
      padding: 1.25rem 1.5rem 2.5rem;

      @include breakpoint($screen-xl) {
        padding: 2.5rem 2rem 2.5rem 0;
      }
    }
  }
  .MuiTypography-body1,
  .MuiTypography-body2 {
    margin-top: 1rem;
  }
  .button-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 2rem;
    flex-wrap: wrap;
  }
  a:not(.MuiButton-root) {
    color: $color-dusk;
    font-size: $font-size-sm;
    text-decoration: none;
    transition: opacity 200ms ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }
}

@import "shared/dist/styles/variables";

.MuiTypography-color {
  &Primary {
    color: $color-blue;
    &:hover {
      color: $color-light-blue;
    }
  }
}
.MuiLink-root {
  cursor: pointer;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: 0.7;
  }
}

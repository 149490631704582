$border-radius-large: 20px;
$border-radius-medium: 0.56rem;
$border-radius-small: 0.23rem;

// These should be rem. We should change this when it's safe to change html font-size to 18px
$spacing-2xs: 2.7px; // 0.15rem;
$spacing-xs: 9px; // 0.5rem;
$spacing-sm: 13.5px; // 0.75rem;
$spacing-md: 18px; // 1rem;
$spacing-lg: 27px; // 1.5rem;
$spacing-xl: 36px; // 2rem;
$spacing-2xl: 72px; // 4rem;
$spacing-3xl: 99px; // 5.5rem;

$spacing-button-3xs: 0.1rem;
$spacing-button-2xs: 0.25rem;
$spacing-button-xs: 0.55rem;
$spacing-button-sm: 0.66rem;
$spacing-button-md: 1rem;
$spacing-button-lg: 1.39rem;

/**
The classes are named using the format `{property}{sides}-{size}`

Where *property* is one of:

* `m` - for classes that set `margin`
* `p` - for classes that set `padding`

Where *sides* is one of:

* `t` - for classes that set `margin-top` or `padding-top`
* `b` - for classes that set `margin-bottom` or `padding-bottom`
* `l` - for classes that set `margin-left` or `padding-left`
* `r` - for classes that set `margin-right` or `padding-right`
* `x` - for classes that set both `*-left` and `*-right`
* `y` - for classes that set both `*-top` and `*-bottom`
* blank - for classes that set a `margin` or `padding` on all 4 sides of the element

Where *size* is one of: `2xs`, `xs`, `sm`, `md`, `lg`, `xl`, `2xl`, `3xl` or `auto` for classes that set the `margin` to auto
 */

$spacers: (
  0: 0,
  2xs: $spacing-2xs,
  xs: $spacing-xs,
  sm: $spacing-sm,
  md: $spacing-md,
  lg: $spacing-lg,
  xl: $spacing-xl,
  2xl: $spacing-2xl,
  3xl: $spacing-3xl,
);

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {

    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}

// Some special margin utils
.m-auto { margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

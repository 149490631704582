@use "../../styles/mixins";
@import 'shared/dist/styles/variables';

.contact-menu-component {
  opacity: 1;
  position: fixed;
  transition: all 200ms ease;

  &:not(.toggled) {
    bottom: -5rem !important;
    opacity: 0;
  }

  .action-button {
    @extend .smallShadow;
    background: $color-dusk;
    border: 0;
    border-radius: 3.5rem;
    cursor: pointer;
    height: 3.5rem;
    outline: none;
    position: relative;
    width: 3.5rem;
    z-index: 1001;


    .contact-menu-icon {
      transform: translateY(5%);
      height: 1.6125rem;
    }

    &.toggled {
      background: $color-lake;

      +.contact-menu {
        &-list {
          visibility: initial;

          .contact-menu-item {
            opacity: 1;
            pointer-events: inherit;
            transform: none;

            &:nth-child(1) {
              transition-delay: 75ms;
            }

            &:nth-child(3) {
              transition-delay: 0ms;
            }
          }
        }
      }
    }
  }

  // .MuiSvgIcon-root {
  //   fill: $color-white;
  //   height: 1.5rem;
  //   left: 50%;
  //   position: absolute;
  //   transform: translate(-50%, -50%);
  //   top: 50%;
  //   width: 1.5rem;
  // }

  .contact-menu {

    &-list {
      bottom: 3.5rem;
      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 0.5rem;
      visibility: hidden;
      z-index: 1000;
    }

    &-item {

      cursor: pointer;
      display: inline-block;
      height: 4rem;
      opacity: 0;
      position: relative;
      pointer-events: none;
      text-align: right;
      transform: translateY(4rem);
      transition: all 100ms ease-in-out;
      white-space: nowrap;
      width: 100%;

      &:nth-child(1) {
        transition-delay: 50ms;
      }

      &:nth-child(2) {
        transition-delay: 75ms;
      }

      &:nth-child(3) {
        transition-delay: 100ms;

      }

      a {
        color: $color-midnight;
        display: block;
        text-decoration: none;
        white-space: nowrap;
      }

      .label {
        @extend .text-small;
        @extend .smallShadow;
        color: $color-midnight;
        background-color: #fff;
        border-radius: 10rem;

        margin-right: 1rem;
        padding: calc(0.75rem - 1px) 1rem 0.75rem;
        position: relative;
        white-space: nowrap;
      }

      .chat-button {
        cursor: pointer;
        border: none;
        background-color: #ffffff00;
        display: inline;
        padding: 0;
        margin: 0;

        appearance: button;
        -moz-appearance: button;
        -webkit-appearance: button;
      }

      .icon {
        @extend .smallShadow;
        background: $color-dusk;
        border-radius: 5rem;
        display: inline-block;
        height: 2.5rem;
        position: relative;
        width: 2.5rem;

        svg {
          height: 1.25rem;
          transform: translate(-5%, 50%);
        }
      }
    }
  }

  svg {
    fill: #fff;

    path,
    circle,
    rect {
      fill: #fff;
    }
  }
}

.material-modal.send-a-message-modal {
  @media (min-width: 769px) {
    .MuiDialog-container {
      height: 27rem;
      width: 40rem;
    }

    .MuiPaper {
      height: 40rem;
      width: 40rem;
    }

    .content {
      padding: 0 1.5rem;
    }
  }
}
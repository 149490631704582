@import "shared/dist/styles/variables";

.text-content-layout {
  margin: 0 auto;
  max-width: 67rem;
  color: $c-text-dark;

  p,
  ul {
    margin: 1rem 0;
  }
  p,
  li {
    line-height: 1.75;
  }
  ul {
    padding-left: 2.5rem;
    list-style-type: disc;
  }
  a {
    color: $color-dusk;
    transition: opacity 200ms ease-in-out;

    &:hover {
      opacity: 0.7 !important;
    }
  }
  @media (min-width: 600px) {
    padding: 2rem 2rem 7rem;
  }
}

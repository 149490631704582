@use "shared/dist/styles/variables.scss";

.glide-path-graph-tooltip {
  background-color: variables.$palette-gray-110;
  border-radius: variables.$border-radius-small;
  min-width: 210px;
  transform: translateY(-120%);

  &__label {
    border-bottom: 1px solid variables.$palette-gray-100;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 variables.$spacing-sm;

    &:last-child {
      border-bottom: none;
    }
  }

  &__text {
    color: variables.$color-white;
    font-size: variables.$font-size-xs;

    &--title {
      @extend .glide-path-graph-tooltip__text;
      font-weight: bold;
    }

    &--right {
      @extend .glide-path-graph-tooltip__text;
      text-align: right;
    }
  }
}

.investment-options {
  .recharts-responsive-container {
    font-size: variables.$font-size-2xs;
    //Fix for re-sizing issue with recharts https://github.com/recharts/recharts/issues/172
    max-width: 99%;
  }
  .recharts-wrapper {
    //we need to set this to stop the graph rendering flat on mobile view
    min-height: 380px;
  }
}

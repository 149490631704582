@use "shared/dist/styles/variables/breakpoints.scss" as b;
@use "shared/dist/styles/variables/colors.scss" as c;
@use "shared/dist/styles/variables/spacing.scss" as s;
@use "shared/dist/styles/variables/typography.scss" as t;
@use "/src/components/clickable/clickable.scss" as clickable;

.ipq-result-option {
  background-color: c.$c-background;
  border-radius: 10px;
  padding: s.$spacing-sm s.$spacing-md s.$spacing-lg;
  border: 2px solid transparent;

  &.side-by-side {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;

    @include b.breakpoint(b.$screen-md) {
      max-width: calc(50% - s.$spacing-md / 2);
      min-width: 360px;
    }

    .form-inputs__select {
      margin-bottom: s.$spacing-lg;
    }

    .ipq__buttons {
      margin-top: auto;
      > * {
        width: 100%;
      }
    }
  }

  &:not(.side-by-side) {
    width: 100%;
  }

  &.is-current {
    border-color: c.$color-dusk;
    position: relative;
  }

  &.is-custom {
    .allocation-details {
      flex-grow: 1;
      margin-bottom: s.$spacing-lg;

      p {
        margin-top: 0;
      }

      .assets-list ~ p {
        margin-top: s.$spacing-lg;
      }
    }
  }

  &:not(.is-custom) {
    .result-summary {
      flex-grow: 1;
    }
  }

  .ipq-result-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .result-header {
    display: flex;
    flex-direction: row;
  }

  .tooltip {
    &-label {
      border-bottom: 1px dashed;
    }
    &-icon > svg {
      height: 1.125rem;
    }
  }

  &-header {
    display: flex;
    flex-direction: row;

    > h5 {
      margin-bottom: 0;
    }
  }

  &-risk-indicator {
    max-width: 10rem;
  }

  .section-header {
    @extend .text-regular;
    align-items: center;
    color: c.$color-midnight;
    display: flex;
    flex-direction: row;
    font-weight: 600;
    margin-bottom: s.$spacing-xs;

    > .info-tooltip {
      display: inline-flex;

      &__text {
        border-bottom: 1px dashed c.$color-midnight;
      }

      &__icon {
        margin-left: s.$spacing-2xs;
      }
    }

    .input-tooltip__content {
      text-align: left;
    }
  }
  .risk-indicator {
    width: 10rem;
  }

  .options {
    &-select {
      background-color: c.$color-white;
    }
    .options {
      &-select {
        background-color: c.$color-white;
      }
    }
  }

    .result-actions {
      display: flex;
      flex-direction: column-reverse;
    }
  }

@use "shared/dist/styles/variables";

.MuiTabs-root.dark-oval {
  padding: 0 5px;
  border-radius: 30px;
  background-color: $background-color;

  .MuiTabs-flexContainer {
    position: relative;
    z-index: 2;
  }

  $indicator-y-margin: 5px;
  .MuiTabs-indicator {
    background-color: $color-lake;
    border-radius: 30px;
    height: calc(100% - #{$indicator-y-margin * 2});
    margin: $indicator-y-margin 0;
    z-index: 1;
  }
  .MuiPaper-root {
    @media (max-width: 480px) {
      border-radius: 0;
    }
  }
  .MuiBox-root {
    padding: 2.25rem 0 0;

    @media (max-width: 1089px) {
      padding-top: 2rem;
    }
  }

  .MuiTab-root {
    @extend .text-large;
    font-weight: 600;
    letter-spacing: normal;
    text-align: center;
    text-transform: none;
    transition: color 600ms;
    flex-grow: 1;
    max-width: inherit;

    &.Mui-selected {
      color: $color-white;
    }

    &:not(.Mui-selected) {
      color: $color-midnight;
      transition: color 200ms ease-in-out;

      &:hover {
        color: $color-grey;
      }
    }
  }
}

@import "shared/dist/styles/variables";

.ff-online-portal {
  .MuiTypography-h1 {
    @extend h1;
  }

  .MuiTypography-h2 {
    @extend h2;
  }

  .MuiTypography-h3 {
    @extend h3;
  }

  .MuiTypography-h4 {
    @extend h4;
  }

  .MuiTypography-h5 {
    @extend h5;
  }

  .MuiTypography-h6 {
    @extend h6;
  }

  .MuiTypography {

    &-body1,
    &-body2 {
      @extend .text-regular;
    }

    &-body2 {
      font-weight: 400;
      color: $text-dark;
    }

    &-caption {
      @extend .text-small;
    }
  }
}
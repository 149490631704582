@use "shared/dist/styles/variables/breakpoints.scss" as b;
@use "shared/dist/styles/variables/colors.scss" as c;
@use "shared/dist/styles/variables/spacing.scss" as s;
@use "shared/dist/styles/variables/typography.scss" as t;

$desktop-width : 600px;
$mobile-width : 90%;

.toast-notification {
    background: white;
    border-radius: s.$border-radius-medium;
    color: c.$text-dark;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    margin: 0 auto;
    padding: s.$spacing-md;
    width: $mobile-width;
    z-index: 1100;

    @include b.breakpoint(b.$screen-md) {
      margin: 0 auto 0 calc($desktop-width / 4);
      width: $desktop-width;
    }

    &__info .toast-notification__icon {
      fill: c.$color-dusk;
    }
  
    &__success .toast-notification__icon {
      fill: c.$color-kawakawa;
    }

    &__warning .toast-notification__icon {
      fill: c.$color-karaka;
    }

    &__error .toast-notification__icon {
      fill: c.$color-puriri;
    }

    .toast-content {
        margin-left: s.$spacing-md;
        text-wrap: wrap;
        width: 100%;

        p {
          margin: 0;
        }
    }
}
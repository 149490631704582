@use "shared/dist/styles/variables" as *;

.account-investment-profile-component {
  $gap-size-mobile: 10px;
  $gap-size-desktop: 20px;
  .investment-funds {
    @extend .section-padding;
    @extend .negative-section-padding;
    display: flex;
    flex-direction: row;
    gap: $gap-size-mobile;
    margin-top: $spacing-md;
    overflow-x: auto;
    padding-bottom: $spacing-md;

    @include breakpoint($screen-md) {
      flex-wrap: wrap;
      gap: $gap-size-desktop;
      overflow-x: hidden;
      margin-top: $spacing-xl;
      padding-bottom: $spacing-lg;
    }
  }

  .investment-fund {
    background-color: $background-color;
    border-radius: 10px;
    flex: 0 0 100%;
    max-width: 90%;
    padding: 20px;

    @include breakpoint($screen-md) {
      flex: 1 1 auto;
      margin: 0 auto;
      max-width: calc(50% - $gap-size-desktop / 2);
      padding: 20px 30px 25px;
    }

    hr {
      margin: 10px 0;
    }
  }

  .fund-header {
    align-items: center;
    margin-bottom: 20px;
  }

  .pairs {
    @extend .text-regular;
    display: flex;
    flex-direction: row;
    line-height: 15px;
    justify-content: space-between;

    &:not(.fund-header) {
      margin: 15px 0;
    }

    > *:first-child {
      margin-right: $spacing-sm;
    }
  }

  .fund-name-and-color {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .color-label {
    $size: 15px;
    border-radius: $size;
    display: inline-block;
    flex: 0 0 $size;
    height: $size;
    margin-right: $spacing-xs;
    width: $size;
  }
}

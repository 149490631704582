@use "shared/dist/styles/variables/spacing" as s;
@use "shared/dist/styles/variables/breakpoints" as b;

.account-tabs-component {
  margin-top: s.$spacing-md;

  @include b.breakpoint(b.$screen-md) {
    margin-top: s.$spacing-lg
  }

  > .MuiTabs-root {
    margin-bottom: s.$spacing-md;

    @include b.breakpoint(b.$screen-md) {
      margin-bottom: s.$spacing-lg
    }
  }
}
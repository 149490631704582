@use "shared/dist/styles/variables/colors.scss" as c;
@use "shared/dist/styles/variables/spacing.scss" as s;
@use "shared/dist/styles/variables/typography.scss" as t;

$card-overlap: 7px;
$current-indicator-outgap: 0.5rem;

.current-indicator {
  background-color: c.$color-dusk;
  color: c.$color-white;
  top: 0;
  right: 0;
  margin: -#{$current-indicator-outgap} -#{$current-indicator-outgap} 0 0;
  padding: s.$spacing-button-2xs $card-overlap;
  position: absolute;
  span {
    display: flex;
    align-items: center;
    line-height: 1rem;
    font-size: t.$font-size-sm;
    svg {
      height: 1.25rem;
    }
  }

  &::before,
  &::after {
    background-color: c.$color-dusk;
    border-left: transparent;
    border-right: transparent;
    border-style: solid;
    color: c.$palette-blue-10;
    content: '';
    height: 0;
    position: absolute;
    width: 0;
  }

  &::before {
    border-bottom: solid c.$color-dusk;
    border-width: $card-overlap $card-overlap 0 0;
    left: -$card-overlap;
    top: 0;
  }

  &::after {
    border-top: solid c.$color-dusk;
    border-width: 0 0 $card-overlap $card-overlap;
    bottom: -$card-overlap;
    right: 0;
  }
}
@use 'shared/dist/styles/variables/colors' as c;
@use 'shared/dist/styles/variables/breakpoints' as b;

.pretty-card {
  background-color: c.$c-background;
  border-radius: 10px;
  flex: 0 1 100%;
  flex-wrap: wrap;
  padding: 20px;

  @include b.breakpoint(b.$screen-xl) {
    flex-basis: calc(50% - 20px);
  }

  &:not(:first-of-type) {
    margin-top: 10px;

    @include b.breakpoint(b.$screen-xl) {
      margin-top: 0;
    }
  }

  .MuiTypography-h6 {
    color: c.$color-blue;
    font-weight: 400;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }
}
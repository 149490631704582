@import "shared/dist/styles/variables";

.dashboard-theme-container {
  .accounts-content-layout {
    background-color: $background-color;
    position: relative;

    @include breakpoint($screen-lg) {
      .accounts-header {
        position: absolute;
      }
    }

    .loading-spinner {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      @media (max-height: 500px) {
        top: 25%;
      }
      @media (max-height: 570px) {
        top: 30%;
      }
    }

    .main-content-padding .profile-quick-actions-component {
      display: none;
      flex: 0 0 auto;
      margin-left: auto;
    }

    .investment-update {
      background-color: #00ae9e;
      border-radius: 10rem;
      display: inline-block;
      font-weight: 300;
      line-height: 3;
      padding: 0 0.5rem 0 0.75rem;
      text-transform: uppercase;

      .MuiSvgIcon-root {
        margin-left: 4px;
        position: relative;
        top: 7px;
      }
    }
    .investment-trend {
      color: $color-white;
    }
    .MuiTypography-caption {
      font-size: 11px;
      font-weight: 300;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    
    .investment-header,
    .investment-value,
    .currency-sign,
    .currency {
      @extend .font-graphik;
    }
    .investment-header,
    .investment-value {
      color: $color-midnight;
    }
    .investment-value {
      font-weight: 300;
    }
    .investment-trend {
      font-size: 1em;
      margin-top: -6px;

      .MuiSvgIcon-root {
        position: relative;
        top: 5px;
      }

      @media (min-width: 401px) {
        font-size: 1.125em;
        margin-top: -4px;

        .MuiSvgIcon-root {
          height: 1.35rem;
          top: 5px;
        }
      }
      @media (min-width: 600px) {
        font-size: 1.25em;
        margin-top: 0;

        .MuiSvgIcon-root {
          height: 1.5rem;
          margin-right: 0.25rem;
          top: 6px;
        }
      }
    }

    .notifications-container {
      margin-top: 1.7rem;

      .notification {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
  }
}

.notificationLink:after {
  content: ">";
  display: inline-block;
  text-decoration: none;  
  padding-left: 5px;
}
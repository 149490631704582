@import "shared/dist/styles/variables";

.MuiSnackbarContent-root {
  position: relative;

  &.MuiTypography-root {
    margin-bottom: 1rem;
  }
  &.success {
    background-color: $color-green;
  }
  &.info {
    background-color: $color-green-info;
    color: $color-black;
    font-weight: normal;

    .MuiSnackbarContent-message {
      a {
        color: $color-light-blue;
      }
      svg {
        color: $color-teal-blue;
      }
    }
  }
  &.error {
    background-color: $color-red;
  }
  &.warning {
    background-color: #FF7A25;
  }
  .MuiSnackbarContent-message {
    padding-left: 0.75rem;
    width: 100%;

    svg {
      margin-right: 1rem;
      width: 1.2em;
      height: 1.2em;
      + span {
        display: block;
      }
    }
    span {
      display: flex;
      align-items: center;
    }
    a {
      color: $color-white;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .MuiSnackbarContent-action {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
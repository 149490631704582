@use "shared/dist/styles/variables/colors" as c;
@use "shared/dist/styles/variables/breakpoints" as b;
@use "shared/dist/styles/variables/spacing" as s;

.account-performance-tab-component {
  position: relative;

  @include b.breakpoint(b.$screen-xl) {
    flex-direction: row;
  }

  .spinner {
    margin-top: s.$spacing-lg;
    width: 100%;
  }
}

@use "shared/dist/styles/variables/spacing.scss" as s;
@use "shared/dist/styles/variables/colors.scss" as c;

.withdraw-confirmation {
  .summary {
    margin-bottom: s.$spacing-md;
    p {
      margin: 0;
    }
  }
}
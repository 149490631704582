@use "./colors.scss";

@mixin kiwisaver-colors {
  background-color: colors.$color-kiwisaver-bg;
  color: colors.$color-kiwisaver-text;
}

@mixin kiwisaver-plan-colors {
  background-color: colors.$color-kiwisaver-plan-bg;
  color: colors.$color-kiwisaver-plan-text;
}

@mixin managed-funds-colors {
  background-color: colors.$color-managed-funds-bg;
  color: colors.$color-managed-funds-text;
}

@mixin investment-funds-colors {
  background-color: colors.$color-investment-funds-bg;
  color: colors.$color-investment-funds-text;
}

@mixin premium-service-colors {
  background: colors.$color-premium-service-bg;
  color: colors.$color-premium-service-text;
}

@mixin lifesaver-colors {
  background-color: colors.$color-lifesaver-bg;
  color: colors.$color-lifesaver-text;
}

@mixin investment-series-colors {
  background-color: colors.$color-investment-series-bg;
  color: colors.$color-investment-series-text;
}

@mixin futureplan-colors {
  background-color: colors.$color-future-plan-bg;
  color: colors.$color-future-plan-text;
}
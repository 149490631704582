@use "shared/dist/styles/variables.scss" as v;
@use "shared/dist/styles/variables/typography.scss";
.advice__callout {
  background: v.$c-background;
  border-radius: v.$border-radius-small;

  &-title {
    color: v.$color-midnight;
    font-size: v.$font-size-sm;
  }

  &-text {
    color: v.$c-text-dark;
  }
}



@use "shared/dist/styles/variables" as *;

.balance-graph {
  &__message {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $spacing-xl;

    @include breakpoint($screen-md) {
      height: 350px;
    }
  }

  &-tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 1.2rem;
    background-color: $color-midnight;
    border-radius: 5px;

    &__date {
      color: rgba($color-white, 0.75);
    }

    &__value {
      color: $color-white;
    }

    &__return {
      .currency__trend-icon {
        display: none;
      }

      .currency-black {
        color: $color-kawakawa;
      }
    }
  }
}

.custom-range-picker-component {
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  .DateRangePickerInput {
    &__withBorder {
      border-radius: 8px;
      overflow: hidden;
    }
  }
  .DateInput_input {
    font-size: 1rem;
    text-align: center;
  }
  .DayPicker {
    &__withBorder {
      border: 1px solid #dbdbdb;
      border-radius: 8px;
      box-shadow: none;
      margin-top: -19px;
      overflow: hidden;
    }
    .DayPickerNavigation_button {
      border-radius: 8px;
      padding: 8px;
    }
  }

  .CalendarDay__selected {
    background-color: $color-teal-blue;
    border-color: $color-teal-blue;
  }
  .CalendarDay__selected_span {
    background-color: $color-light-teal-blue;
    color: $color-black;
    border-color: scale-color($color-light-teal-blue, $lightness: 50%);
  }
}

@use "shared/dist/styles/variables/breakpoints.scss" as b;
@use "shared/dist/styles/variables/colors.scss" as c;
@use "shared/dist/styles/variables/spacing.scss" as s;
@use "shared/dist/styles/variables/typography.scss" as t;

.ipq-results {
  &-modal {
    .results {
      &-description {
        @extend .text-regular;
      }

      .notification {
        @extend .notification;
      }
    }
  }

  &-page {
    .results {
      &-description {
        &,
        > p {
          @extend .text-large;
          color: c.$c-text-dark;
        }
      }
    }
  }

  &-modal,
  &-page {
    color: c.$c-text-dark;

    h4 {
      color: c.$color-midnight;
    }

    .ipq__links {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-top: s.$spacing-lg;

      @include b.breakpoint(b.$screen-md) {
        flex-direction: row;
        justify-content: center;
      }

      > * {
        margin-top: s.$spacing-md;

        @include b.breakpoint(b.$screen-md) {
          margin-top: 0;

          &:not(:first-child) {
            margin-left: s.$spacing-xl;
          }
        }
      }
    }

    .results-wrapper {
      display: flex;
      flex-direction: column;
      gap: s.$spacing-md;
      margin-bottom: s.$spacing-md;

      @include b.breakpoint(b.$screen-md) {
        flex-direction: row;
      }
    }
  }
}

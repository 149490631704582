/* stylelint-disable */
@use "shared/dist/styles/variables";

.embeddedServiceHelpButton {
  visibility: hidden;
  .helpButton {
    .uiButton {
      background-color: variables.$color-midnight !important;
      border-color: variables.$color-midnight;
      border-style: solid;
      border-width: 1px 1px 0;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
      color: variables.$color-white !important;
      font-family: "Graphik", sans-serif;
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:focus,
      &:hover {
        background-color: variables.$color-lake !important;
        border-color: variables.$color-lake;
        border-style: solid solid none;
      }

      span {
        text-decoration: none !important;
      }
    }
  }

  .embeddedServiceIcon {
    display: inline-block !important;
    margin-right: 0;
  }
}

.hide-live-chat .embeddedServiceHelpButton {
  display: none !important;
}

.embeddedServiceSidebar {
  .header-container[c-embeddedServiceHeader_embeddedServiceHeader] {
    height: 55px;
    max-height: 55px;

    svg[c-embeddedServiceHeader_embeddedServiceHeader] {
      height: 30px;
      width: 27px;
    }

    h2[c-embeddedServiceHeader_embeddedServiceHeader] {
      font-family: "Graphik", sans-serif;
    }

    button[c-embeddedServiceHeader_embeddedServiceHeader] {
      display: inline-block;
      margin-right: 4px;
      padding: 4px;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        &::before {
          left: 0;
          top: 0;
        }
      }

      lightning-icon[c-embeddedServiceHeader_embeddedServiceHeader] {
        padding: 0;
      }
    }
  }

  .disclosure-container[c-embeddedServiceHeader_embeddedServiceHeader] a[c-embeddedServiceHeader_embeddedServiceHeader] {
    color: variables.$color-dusk;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .embeddedServiceSidebarForm {
    text-align: left !important;

    .embeddedServiceSidebarFormField {
      .uiInput {
        color: variables.$color-midnight;
        margin-bottom: 28px;

        .uiLabel-left {
          display: inline-block;
          font-size: 0.8rem;
          margin-bottom: 4px;
          text-transform: lowercase;

          &::first-letter {
            text-transform: capitalize;
          }

          .required {
            color: inherit;
            left: 0;
            position: inherit;
          }
        }

        input {
          font-size: 0.8rem;
        }

        select::after {
          top: calc(50% + 0.6rem);
        }

        input,
        select {
          border-color: variables.$color-seafoam !important;
          border-radius: 5em !important;
          padding: 0 1em !important;
        }
      }
    }
  }

  .embeddedServiceSidebarButton {
    background-color: variables.$color-seafoam;
    border: 1px solid variables.$color-seafoam;
    height: auto;
    margin-right: 4px;
    padding: 0.55rem 1.5rem;
    transition: all 0.3s;

    &:hover,
    &:focus,
    &--default:hover,
    &--default:focus {
      background-color: variables.$color-white !important;
      border-color: variables.$color-dusk;
      text-shadow: none;
      color: variables.$color-midnight !important;
    }

    .label {
      color: variables.$color-midnight !important;
      display: inline-block;
      font-size: 0.8rem;
      height: auto;
      overflow: visible;
    }
  }

  button {
    border-radius: 5em;
    padding: 0 1.5em;
    transition: all 0.3s;
    width: auto;

    .label {
      transition: all 0.3s;
    }
  }

  .sidebarBody {
    .embeddedServiceSidebarDialogState {
      color: variables.$color-midnight;

      /* Contact us - thanks screen */
      .embeddedServiceIcon.dialogIcon {
        justify-content: start;

        &.large svg circle {
          fill: variables.$color-kawakawa;
        }
      }

      #dialogTextTitle {
        font-family: "Graphik", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        text-align: left !important;
      }

      #dialogTextBody {
        font-size: 0.9rem;
        padding: 0;
        text-align: left !important;
      }

      .dialogButtonContainer {
        text-align: left;
      }
    }
  }
}

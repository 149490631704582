@use "shared/dist/styles/variables" as variables;

@mixin input-error {
  background-color: variables.$palette-red-10;
  border-color: variables.$palette-red-100;

  &:focus-within {
    border-color: variables.$palette-red-100;
  }
}

@mixin input-color {
  border: 1px solid variables.$color-seafoam;
  border-radius: 25px;
  color: variables.$color-lake;
  outline: none;

  &:focus {
    border-color: variables.$color-kikorangi;
  }
}

@import "shared/dist/styles/variables";

.contact-details-form-component {
  .info-message-component {
    margin-bottom: 2rem;

    @media (min-width: 1180px) {
      margin-bottom: 1.5rem;
    }
  }
  .MuiTypography-h6 {
    margin-bottom: 1rem !important;

    @media (max-width: 900px) {
      text-align: left !important;
    }
    &.address-title {
      display: flex;
      flex-wrap: initial;
      justify-content: space-between;

      @media (max-width: 480px) {
        margin-top: 1.5rem;
      }
      @media (min-width: 481px) and (max-width: 1179px) {
        margin-top: 2rem;
      }
    }
  }
  .address-main > div {
    display: block !important;
  }
  .MuiGrid-grid-xs-4:nth-child(2) {
    padding-bottom: 0;
  }
  .action-buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    .MuiButton-root:first-child {
      margin-right: 1rem;
    }
    @media (min-width: 1180px) {
      padding: 0 0.75rem;
    }
  }
  .form {
    padding: 0 !important;
    > .MuiGrid-container {
      > .MuiGrid-item {
        flex: none;
        display: block;
        width: 100%;
      }
    }
    .MuiGrid-spacing-xs-3 {
      margin: 0;
      width: 100%;
    }
    @media (max-width: 480px) {
      .MuiGrid-item {
        &:not(:first-child) {
          padding-top: 0;
        }
      }
    }
    @media (min-width: 481px) and (max-width: 600px), (min-width: 769px) and (max-width: 900px) {
      .MuiGrid-item {
        &:first-child {
          padding-bottom: 0;
        }
        &:not(:first-child) {
          padding-top: 0;
        }
      }
    }
    @media (max-width: 1179px) {
      .MuiGrid-spacing-xs-3 > .MuiGrid-item {
        padding: 0;
      }
    }
    @media (min-width: 601px) and (max-width: 768px), (min-width: 901px) {
      .MuiGrid-grid-xs-3 {
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%;
      }
      .MuiGrid-grid-xs-4 {
        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
      }
      .MuiGrid-grid-xs-5 {
        flex-grow: 0;
        max-width: 41.666667%;
        flex-basis: 41.666667%;
      }
    }
    @media (min-width: 1180px) {
      margin-left: -0.75rem;
      margin-right: -0.75rem;
    }
  }

  .address-clear-button {
    color: $color-teal-blue;
    cursor: pointer;
    text-transform: initial;
    padding: 0 0.4rem;
    > .text {
      display: inline-flex;
      padding-left: 0.4rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .address-main {
    position: relative;
  }
  .address-menu {
    background-color: $color-white;
    border: 2px solid $color-blue;
    border-top: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-top: -1.5rem;
    position: absolute;
    padding: 0.5rem 0;
    width: 100%;
    z-index: 999;

    .item {
      cursor: pointer;
      background-color: $color-white;
      padding: 0.5rem 1rem;
      transition: background-color 200ms ease-in-out;

      &:hover,
      &.item-selected {
        background-color: $color-light-grey;
      }
    }
  }
}

@use "shared/dist/styles/variables" as *;

.account-performance-summary-component {
  width: 100%;

  .currency:not(.details-row, .total-value) {
    font-weight: 600;
  }

  .MuiTable-root {
    @media (max-width: 480px) {
      margin: 0 -1rem;
      width: calc(100% + 2rem);
    }
  }

  .MuiTableRow-root {
    @extend .text-large;
    padding: 4rem 0;
    .MuiTableCell-root {
      border-bottom: none;
      border-top: 1px solid rgba(224, 224, 224, 1);
      width: 100%;
      max-width: 100%;
      font-weight: 600;
    }

    &:first-child {
      .MuiTableCell-root {
        border-top: none;
      }
    }
  }
  .label {
    color: $color-midnight;
  }

  .details-row {
    .MuiTableCell-root {
      font-weight: 400;
      color: $color-smoke;
      font-size: 15px;
      padding: 0.5rem 1rem;
    }

    .MuiTableRow-root.details-row.expanded {
      border-top: none;
    }
    &.first {
      .MuiTableCell-root {
        border: none;
        padding-bottom: 0.5rem;
      }
    }

    .tooltip {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      cursor: help;
      .tooltip-label {
        color: $color-dusk;

        &:hover {
          color: $color-dusk;
        }
      }
      .tooltip-icon {
        @extend .text-regular;
        margin-left: $spacing-2xs;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .MuiSvgIcon-root {
          height: 1.25rem;
          fill: $color-lake;
        }
      }
    }
  }

  $animation-time: 0.25s;
  .performance-summary-icon {
    transition: transform $animation-time;
    &.expanded {
      transform: rotate(180deg);
    }

    .MuiSvgIcon-root {
      fill: $color-smoke;
    }
  }
}

@use "shared/dist/styles/variables.scss" as v;
@use "shared/dist/styles/variables/typography.scss";

.privacy-statement {
  color: inherit;
  display: inline-block;
  font-size: inherit;
  text-decoration: underline;
}

.privacy-statement-modal {
  &__paragraph {
    color: v.$c-text-default;
    font-size: v.$font-size-sm;
    line-height: 1.67;
    margin-bottom: v.$spacing-md;
    text-align: left;
    width: 100%;
  }

  &__title {
    color: v.$c-text-dark;
    padding-bottom: v.$spacing-sm;
  }
}

@font-face {
  font-display: swap;
  font-family: "Graphik";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Graphik/Graphik-Regular-Web.woff2") format("woff2"),
    url("./fonts/Graphik/Graphik-Regular-Web.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Graphik";
  font-style: italic;
  font-weight: normal;
  src: url("./fonts/Graphik/Graphik-RegularItalic-Web.woff2") format("woff2"),
    url("./fonts/Graphik/Graphik-RegularItalic-Web.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Graphik";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/Graphik/Graphik-Medium-Web.woff2") format("woff2"),
    url("./fonts/Graphik/Graphik-Medium-Web.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Graphik";
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/Graphik/Graphik-Bold-Web.woff2") format("woff2"),
    url("./fonts/Graphik/Graphik-Bold-Web.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Graphik";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/Graphik/Graphik-Light-Web.woff2") format("woff2"),
    url("./fonts/Graphik/Graphik-Light-Web.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Miller";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Miller/Miller Display Roman.eot");
  src: url("./fonts/Miller/Miller Display Roman.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Miller/Miller Display Roman.woff2") format("woff2"),
    url("./fonts/Miller/Miller Display Roman.woff") format("woff");
  unicode-range: U+00-FF; /* Fix for macron ā showing wrongly on Miller */
}

@font-face {
  font-display: swap;
  font-family: "Miller";
  font-style: italic;
  font-weight: normal;
  src: url("./fonts/Miller/Miller Display Italic.eot");
  src: url("./fonts/Miller/Miller Display Italic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Miller/Miller Display Italic.woff2") format("woff2"),
    url("./fonts/Miller/Miller Display Italic.woff") format("woff");
  unicode-range: U+00-FF; /* Fix for macron ā showing wrongly on Miller */
}

@font-face {
  font-display: swap;
  font-family: "Miller";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/Miller/Miller Display Light.eot");
  src: url("./fonts/Miller/Miller Display Light.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Miller/Miller Display Light.woff2") format("woff2"),
    url("./fonts/Miller/Miller Display Light.woff") format("woff");
  unicode-range: U+00-FF; /* Fix for macron ā showing wrongly on Miller */
}

@font-face {
  font-display: swap;
  font-family: "Miller";
  font-style: italic;
  font-weight: 300;
  src: url("./fonts/Miller/Miller Display Light Italic.eot");
  src: url("./fonts/Miller/Miller Display Light Italic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Miller/Miller Display Light Italic.woff2") format("woff2"),
    url("./fonts/Miller/Miller Display Light Italic.woff") format("woff");
  unicode-range: U+00-FF; /* Fix for macron ā showing wrongly on Miller */
}
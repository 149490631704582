.content {
  .scroll-zone {
    height: 100%;

    &.iframe {
      margin: 0px;
      padding: 0px;
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    &.iframe-loading {
      display: none;
    }

    iframe {
      border: 0px;
      margin-bottom: 0px;
      width: 100%;
      max-width: 100%;
      flex: 1;
    }
  }
}

@import "shared/dist/styles/variables.scss";

.ff-online-portal .custom-icon.custom-icon {
  &:not(.icon-error) {
    fill-opacity: 0.8;
  }

  polygon {
    stroke: $color-lake;
  }

  path {
    stroke: $color-lake;
    stroke-width: 1px;
  }

  // Some of our svgs need little fixes
  &.icon-details,
  &.icon-calculator {
    &:not(.light):not(.lake) path {
      fill: $color-lake;
    }
  }

  &.icon-refresh path {
    stroke: transparent;
  }

  &.light {
    polygon,
    path {
      fill: white;
      stroke: white;
    }
  }

  &.lake {
    polygon,
    path {
      fill: $color-dusk;
      stroke: $color-dusk;
    }
  }

  &.grey {
    polygon,
    path {
      fill: $text-light;
      stroke: $text-light;
    }
  }
}

.change-contributions {
  &__modal {
    .MuiDialogTitle-root.header {
      margin-bottom: 0;
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 1rem;
  justify-content: space-between;

  @media (max-width: 769px) {
    display: flex;
    flex-direction: column;
  }
}

.modal-button {
  &.MuiButton-root {
    padding: 0.5rem 1.2rem;
  }
}
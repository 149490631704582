@import 'shared/dist/styles/variables';

.account-transactions-tab-component {
  .MuiPaper-root {
    padding: 2rem 1rem;

    @media (min-width: 481px) {
      flex-grow: 1;
      padding: 1.5rem;
    }
  }
}
@import 'shared/dist/styles/variables';

.redirecting-circle-bg {
  position: absolute;
  circle {
    animation: none;
    stroke-dasharray: none;
    color: $color-light-grey;
  }
}
#logged-out-container  {
  background-image: url("../../assets/images/king-fisher-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.primary-button {
  background-color: $color-blue;
  padding: 10px 20px;
  color: $color-white;
  border: none;
  box-shadow: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
@use "shared/dist/styles/variables/colors.scss" as c;

.ff-online-portal {
  .MuiTooltip {
    &-popper {
      z-index: 3000;
    }
    &-tooltip {
      font-weight: normal;
      top: 1.25rem;
      font-size: 12px;
      background-color:  c.$palette-gray-110;

      @media (min-width: 600px) {
        top: 0.4rem;
      }
    }
  }
}

@import "shared/dist/styles/variables.scss";
@import "shared/dist/styles/variables/breakpoints.scss";
@import "./forms.scss";

@mixin padding-y {
  overflow-y: auto;
  padding-bottom: 30px;
  padding-top: 30px;

  @include breakpoint($screen-md) {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

$sidebar-width: 280px;

.dashboard-theme-container {
  background-color: $background-color;
  display: flex;
  flex-direction: row;

  &,
  .main-content {
    height: 100%;
  }

  .notifications-container {
    margin-bottom: 4rem;

    .notification:not(:first-of-type) {
      margin-top: $spacing-lg;
    }
  }

  .accounts-header,
  .MuiBottomNavigation-root {
    flex: 0 0 auto;
  }

  .main-content > .MuiContainer-root,
  .main-content-padding {
    @extend .section-padding;
    @include padding-y;
    flex: 1 1 0;

    > * {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: $content-width-narrow;
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-width: 0; // without this it'll grow bigger than the screen
    position: relative;
    width: 100%;

    &-padding {
      > section {
        display: flex;
        flex-direction: row;

        > * {
          flex: 1 1 auto;
        }
      }
    }
  }

  + .contact-menu-component {
    bottom: 5rem;
    right: 1rem;
    z-index: 1;

    @media (min-width: 769px) {
      bottom: 1.5rem;
      right: 1.5rem;
    }

    @media (min-width: 1069px) {
      bottom: 2rem;
      right: 2rem;
    }
  }
}

.MuiDrawer-root {
  height: 100vh;
  overflow-y: auto;

  .MuiPaper-root {
    @include padding-y;
    background-color: $color-lake;
    border: 0;
    bottom: 0;
    position: relative;
    white-space: nowrap;
    width: $sidebar-width;
  }
}

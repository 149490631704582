@import "shared/dist/styles/variables";

.pir-details-content .form-message-component {
  padding: 0 !important;
}

.pir-details-content {
  a {
    display: inline-flex;
    align-items: center;
    svg {
      font-size: inherit;
    }
  }
}

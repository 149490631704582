@use "../../styles/mixins";
@import "shared/dist/styles/variables.scss";

.dashboard-theme-container {
  .MuiBottomNavigation-root {
    background-color: $color-midnight;
    display: flex;
    height: 4rem;
    width: 100%;

    .MuiBottomNavigationAction-root {
      min-width: 0;
      opacity: 0.5;
      padding-top: 0;
      transition: all 150ms ease-in-out;

      &.Mui-selected,
      &:hover {
        opacity: 1;
      }
    }

    .MuiSvgIcon-root {
      fill: $color-white;
    }

    .MuiBottomNavigationAction-label {
      color: $color-white;

      &.Mui-selected {
        font-size: 0.75rem;
      }
    }

    @include breakpoint($screen-md) {
      display: none;
    }
  }
}

.MuiMenuItem-root {
  white-space: nowrap;
}

.navigation-mobile {
  .MuiPopover-paper {
    @extend .mediumShadow;
    background-color: $color-white;
    margin-left: 0.5rem;
    margin-top: -2.6rem;
  }

  .MuiList-padding {
    padding: 0;
  }

  .MuiMenuItem-root {
    white-space: nowrap;
    padding: 10px 16px;
  }

  &-link {
    @extend .text-large;
    display: flex;
    align-items: center;
    line-height: $font-line-height-md;
    color: $color-midnight !important;
    text-decoration: none;
    padding-right: $spacing-lg;

    .MuiSvgIcon-root {
      height: 1.5rem;
      padding-right: $spacing-xs;
    }
  }

  .log-out-link {
    color: $color-puriri !important;
  }

  .Mui-selected .navigation-mobile-link {
    opacity: 1;
  }
}

@media screen and (min-width: 769px) {
  .navigation-mobile {
    .MuiPopover-paper {
      visibility: hidden;
    }
  }
}

@import "shared/dist/styles/variables";

.change-password-container {
  padding: 0 !important;

  .MuiTypography {
    &-body1 {
      color: $color-smoke;
    }
    &-caption {
      margin-bottom: 1rem !important;
    }
    &-h5 {
      margin-bottom: 1.25rem;
    }
  }
  .login-field-group .MuiFormControl-root {
    margin: 0.5rem 0 1rem;
  }
  .action-buttons {
    margin-top: 1.5rem;
  }
}

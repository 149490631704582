@use "shared/dist/styles/variables.scss" as v;
.switch__modal {
  .notification {
    margin-bottom: v.$spacing-md;
  }
  &-buttons {
    display: flex;
    justify-content: space-between;
    gap: v.$spacing-lg;

    &-download:hover {
      .icon_download {
        path {
          stroke: v.$color-white;
        }
      }
    }
    svg {
      height: 20px;
      width: 20px;
      vertical-align: bottom;
      path {
        stroke: v.$color-lake;
      }
    }
    :first-child {
      text-align: left;
      flex: 2;
    }
  }
  &-form {
    display: flex;
    flex-wrap: wrap;
    gap: v.$spacing-sm;
    justify-content: space-between;

    .MuiOutlinedInput-input {
      padding: v.$spacing-button-sm v.$spacing-button-md;
    }

    > div {
      h6 {
        @extend .text-small;
        @extend .text-bold;
        color: v.$c-text-dark;
      }
      flex: 0 1 calc(50% - v.$spacing-sm);
    }
  }
}

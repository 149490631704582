// Notice: If you are changing values here, you might also want to change the values in hooks/useMedia.ts
//320+ Mobile
$screen-sm: 480px;
//480+ Tablet
$screen-md: 768px; // portrait
$screen-lg: 1024px; // landscape
//1280+ Desktop
$screen-xl: 1280px;
$screen-2xl: 1536px;

$content-width-narrow: 950px;
$content-width-wide: 1200px;
$banner-width: 1440px;

@mixin mobile() {
  @media (max-width: $screen-md) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: $screen-md) and (max-width: #{$screen-xl - 1}) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: $screen-xl) {
    @content;
  }
}

@mixin breakpoint($size) {
  @media (min-width: $size) {
    @content;
  }
}

$section-spacing-mobile: 18px;
$section-spacing-desktop: 36px;

// .narrow {
//   margin-left: auto;
//   margin-right: auto;
//   max-width: $content-width-wide;
//   width: 100%;
// }

.section-padding {
  padding-left: $section-spacing-mobile;
  padding-right: $section-spacing-mobile;

  @include breakpoint($screen-md) {
    padding-left: $section-spacing-desktop;
    padding-right: $section-spacing-desktop;
  }
}

.negative-section-padding {
  margin-left: -#{$section-spacing-mobile};
  margin-right: -#{$section-spacing-mobile};

  @include breakpoint($screen-md) {
    margin-left: -#{$section-spacing-desktop};
    margin-right: -#{$section-spacing-desktop};
  }
}

.narrow-padding {
  @extend .section-padding;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(#{$content-width-wide} + #{$section-spacing-mobile} * 2);
  width: 100%;

  @include breakpoint($screen-md) {
    max-width: calc(#{$content-width-wide} + #{$section-spacing-desktop} * 2);
  }
}

// Don't mix .mobile with .desktop if one of them is not enough use the breakpoint mixin defined above.
.mobile-sm {
  &-hidden {
    @media (max-width: #{$screen-sm - 1}) {
      display: none !important;
    }
  }
  &-only {
    @media (min-width: $screen-sm) {
      display: none !important;
    }
  }  
}
.mobile {
  &-hidden {
    @media (max-width: #{$screen-md - 1}) {
      display: none !important;
    }
  }
  &-only {
    @media (min-width: $screen-md) {
      display: none !important;
    }
  }
}
.tablet {
  &-hidden {
    @media (min-width: $screen-md) {
      display: none !important;
    }
  }
  &-only {
    @media (max-width: $screen-md), (min-width: #{$screen-xl - 1}) {
      display: none !important;
    }
  }
}
.desktop {
  &-hidden {
    @media (min-width: $screen-xl) {
      display: none !important;
    }
  }
  &-only {
    @media (max-width: #{$screen-xl - 1}) {
      display: none !important;
    }
  }
}
@use "shared/dist/styles/variables/colors.scss" as variables;
.ff-online-portal {
  .MuiCheckbox-root,
  .MuiRadio-root {
    padding: 5px;

    $radio-size: 28px;
    .MuiSvgIcon-root {
      height: $radio-size;
      width: $radio-size;
    }
  }

  .MuiCheckbox-root {
    &:checked {
      background-color: variables.$color-dusk;
    }
  }

  .checkbox-label {
    align-items: center;
    display: flex;
    flex-direction: row;
    label {
      @extend .text-regular;
      margin: 0;

      &.form-checkbox {
        flex: 0 0 auto;
      }
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    appearance: textfield;
  }
}

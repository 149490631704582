@import 'shared/dist/styles/variables';

.progress-bar {
  position: relative;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: $color-smoke-10;

  &.sm {
    height: 6px;
    border-radius: 3px;
  }

  &.md {
    height: 10px;
    border-radius: 5px;
  }

  &__indicator {
    height: 100%;
    border-radius: inherit;
    background-color: $color-dusk;
  }
}

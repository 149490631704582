@import "shared/dist/styles/variables";

@keyframes blinker {
  50% {
    opacity: 0.35;
  }
}
.MuiAppBar-root .MuiTabs-root {
  height: 100%;

  .MuiTabs-flexContainer {
    height: 100%;
    padding: 10px 0;
  }

  svg {
    fill: $color-blue;
  }

  .MuiTab-root {
    height: 100%;
    letter-spacing: normal;
    margin-right: 1rem;
    padding: 0;
    text-transform: inherit;

    &.account-summary-tab {
      min-width: unset;
      
      @media (min-width: 420px) {
        margin-right: 3rem;
      }
    }

    &:not(.account-summary-tab) {
      min-width: 7.5rem;

      @media (min-width: 420px) {
        min-width: 9rem;
      }

      .MuiTab-wrapper {
        background-color: $color-light-grey;
      }
    }
    
    .MuiTab-wrapper {
      align-items: flex-start;
      border-radius: 0.25rem;
      padding: 15px 10px;
      transition: all 200ms ease-in-out;

      &.loading {
        animation: blinker 1s linear infinite;
      }
    }
    .tab-label, .tab-value {
      display: block;
    }
    .tab-value .currency-black {
      @extend .h5;
      @extend .font-graphik;
      color: $color-midnight;
      font-weight: 300;
      transition: all 200ms ease-in-out;
    }
    .tab-label {
      @extend .text-small;
      color: $text-light;
      font-weight: 600;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }

  .MuiTabs-indicator {
    background: $color-dusk;
    border-radius: 1px;
    height: 2px;
  }

  .MuiTabs-scrollButtons {
    transition: width 150ms;
    &.Mui-disabled {
      width: 0;
    }
  }
}

/* IE 11 */
@media (min-width: 769px) and (-ms-high-contrast:none) {
  *::-ms-backdrop, .MuiAppBar-root .MuiTabs-root {
    margin-left: 12.5rem;
  }
}
@use 'shared/dist/styles/variables/breakpoints.scss' as b;
@import "shared/dist/styles/variables";

.table-content-wrapper {
    @media (max-width: 480px) {
        margin: 0 -#{$section-spacing-mobile};
        width: calc(100% + #{$section-spacing-mobile * 2});
    }
}
.MuiTable-root .MuiTableCell-head {
    $border-style: 1px solid $color-dusk;
    font-weight: 600;
    background-color: $color-sky-25;
    color: $color-dusk;
    border-top: $border-style;
    border-bottom: $border-style;

    @media (max-width: 480px) {
        font-size: 0.875em;
    }
}
.MuiTableHead-root .MuiTableCell-root {
    white-space: nowrap;
}
.MuiTableBody-root .MuiTableCell-root {
    color: $c-text-default;
    font-weight: 400;
}
.MuiTableRow-root {
    @media (max-width: #{b.$screen-xl - 1}) {
        &.header-row {
            .MuiTableCell-root,
            .DateNZ {
                border-bottom: 0;
                padding-top: 1rem;
            }
        }
    }
}
.MuiTableCell-root {
    white-space: nowrap;
    @media (max-width: 610px), (min-width: 769px) and (max-width: 920px) {
        max-width: 7rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:first-child {
        padding-left: 1rem;
    }
    @media (min-width: 769px) and (max-width: 920px), (min-width: 481px) and (max-width: 610px) {
        font-size: 0.85em;
    }
}
.currency-black {
    color: $color-teal-blue;
}
.action-button {
    margin-top: 1rem;
    text-align: center;
}
